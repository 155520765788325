import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty, getUserData, uploadPics1 } from "../../services/api";
import { useState, useEffect, useRef } from "react";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_9 from "../Images/BecomeHost/step_9.png";
//images end
import { validatePhoneNumber } from "../Validations";
import { FILE_TYPES } from "../Validations";
import man from "../Images/BecomeHost/man.png";
import addImage from "../Images/BecomeHost/add-image.svg";
import VerifyAnyMobile from "../../../../modals/VerifyAnyMobile/VerifyAnyMobile";
import MobileInput from "../../../../Inputs/MobileInput";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import VerifiedIcon from '@mui/icons-material/Verified';

const OWNERTYPES = ['Property Owner','Agent','Manager','Housing Association','Tenant'];
const LANGUAGES = ['English','Hindi','Telugu'];

const ContactDetails = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    // const {user, getUser} = useUser();
    const [status,setStatus] = useState('')
    const [subscription, setSubsciption] = useState("Basic");
    const imageRef = useRef();
    const [image, setImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState("");
    const [formData,setFormData] = useState({
        contact_person_designation : '',
        contact_person_name : user?.personalInfo?.fullName || '',
        contact_person_number : user?.personalInfo?.mobile || '',
        contact_person_email : user?.personalInfo?.email || '',
        alternate_person_name : '',
        alternate_person_number : '',
        alternate_person_email : '',
        languages : [],
        // privacy_policy : false,
        // terms_and_conditions : false,
        // sharing_information : false,
        image : user?.personalInfo?.image || '',
        mobile_verified : false,
    })
    const [errors,setErrors] = useState({})
    // const [showTermsAndConditionsModel, setTermsAndConditionsModel] = useState(false);
    // const [showPrivacyAndPolicyModel, setPrivacyAndPolicyModel] = useState(false);
    const [showVerifyMobile,setShowVerifyMobile] = useState(false);
    
    // const handlePrivacyAndPolicyAgreement = () => {
    //     setFormData({ ...formData, privacy_policy: true });
    // }
    // const handleAgreement = () => {
    //     setFormData({ ...formData, terms_and_conditions: true });
    // }
    // const handlePrivacyAndPolicy = (e) => {
    //     if (formData.privacy_policy) {
    //       setFormData({ ...formData, privacy_policy: false });
    //     } else {
    //       setPrivacyAndPolicyModel(true)
    //     }
    // }
    // const handleChangeTermsAndCond = (e) => {
    //     if (formData.terms_and_conditions) {
    //       setFormData({ ...formData, terms_and_conditions: false });
    //     } else {
    //       setTermsAndConditionsModel(true)
    //     }
    // }
    const onUploadImage = (e) => {
        const file = e.target.files[0];
        if (!(FILE_TYPES.includes(file.type))) {
            e.target.value = null;
            alert(`Only JPEG, JPG and PNG files allowed for upload.`, "error");
            return null;
        }
        if (file) {
            setImage(file);
            const url = URL.createObjectURL(file);
            setPreviewUrl(url);
        }
    }
    const onChange = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const onChangeInArrey = (name,value) => {
        setFormData((prevState) => {
            const updatedPolicies = prevState?.[name]?.includes(value)
              ? prevState?.[name]?.filter(policy => policy !== value)
              : [...prevState?.[name], value];
            
            return { ...prevState, [name]: updatedPolicies };
        });
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    }
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        return regex.test(email) ? '' : 'Invalid email format';
    };
    const validateName = (name) => {
        const regex = /^[A-Za-z\s]+$/;
        const trimmedName = name.trim();
        if (!trimmedName) {
            return "Contact name is required";
        } else if (!regex.test(trimmedName)) {
            return 'Contact name can only contain letters';
        } else if (trimmedName.length < 3) {
            return 'Contact name should be minimum 2 letters';
        } else if (trimmedName.length > 50) {
            return 'Contact name should be less than 50 letters';
        }
        return '';
    };
    const validateNumber = () => {
        let error = validatePhoneNumber(formData?.contact_person_number);
        if (!error) {
            if ((formData?.contact_person_number ===  user?.personalInfo?.mobile) && user?.personalInfo?.mobileVerified) {
                error = ""
            } else if (!(formData?.mobile_verified)) {
                error = "Please verify your mobile number";
            }
        }
        return error;
    }
    const validateForm = () => {
        const newErrors = {
            "contact_person_designation": formData?.contact_person_designation ? '' : "Designation is required", 
            "contact_person_name": formData?.contact_person_name ? validateName(formData?.contact_person_name) : "Contact person name is required", 
            "contact_person_number": formData.contact_person_number ? validateNumber(formData.contact_person_number) : 'Contact number is required',
            "contact_person_email": formData.contact_person_email ? validateEmail(formData.contact_person_email) : 'Email is required',
            // "privacy_policy": formData?.privacy_policy === true ? "" : 'Privacy policy selection is required',
            // "terms_and_conditions": formData?.terms_and_conditions === true ? "" : 'Terms and conditions selection is required',
            // "sharing_information": formData?.sharing_information === true ? "" : 'This selection is required',
            'alternate_person_number': formData.alternate_person_number ? validatePhoneNumber(formData.alternate_person_number) : '',
            'alternate_person_email': formData.alternate_person_email ? validateEmail(formData.alternate_person_email) : '',
            'languages': formData?.languages?.length > 0 ? '' : "Select atlease one language",
        };
        // console.log("errors", newErrors);
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    const onBack = () => {
        navigate(`/apps/property/gallery/${location_id}`)
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async () => {
        if(validateForm()){
            try{
                setShowLoader(true)
                let resp
                let imageRes
                if(image){
                    const imageData = new FormData();
                    imageData.append('image', image);
                    imageRes = await uploadPics1(imageData)
                }
                if(imageRes) formData.image = imageRes?.data.imageUrl
                if(status === 'Incomplete'){
                    resp = await updateProperty({property_manager_details : {...trimFormData(formData), mobile_verified : true}}, location_id);
                    setShowLoader(false)
                    alert('Property is updated')
                    navigate('/')
                }else{
                    if(status === 'Edited'){
                        resp = await updateProperty({property_manager_details : trimFormData(formData),status : 'Under Review'},location_id)
                    }else{
                        resp = await updateProperty({property_manager_details : trimFormData(formData)},location_id)
                    }
                    setShowLoader(false)
                    alert('Property is updated')
                    navigate('/')
                }
            }catch(error){
                console.log(error)
            }
        }
    }
    function scrollToElementWithOffset(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop();
        getUser();
    },[])
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData({
                contact_person_designation : resp?.data?.property_manager_details?.contact_person_designation || '',
                contact_person_name : resp?.data?.property_manager_details?.contact_person_name || user?.personalInfo?.fullName || '',
                contact_person_number : resp?.data?.property_manager_details?.contact_person_number || user?.personalInfo?.mobile || '',
                contact_person_email : resp?.data?.property_manager_details?.contact_person_email || user?.personalInfo?.email || '',
                alternate_person_name : resp?.data?.property_manager_details?.alternate_person_name || '',
                alternate_person_number : resp?.data?.property_manager_details?.alternate_person_number || '',
                alternate_person_email : resp?.data?.property_manager_details?.alternate_person_email || '',
                languages : resp?.data?.property_manager_details?.languages || [],
                privacy_policy : resp?.data?.property_manager_details?.privacy_policy || false,
                terms_and_conditions : resp?.data?.property_manager_details?.terms_and_conditions || false,
                sharing_information : resp?.data?.property_manager_details?.sharing_information || false,
                image : resp?.data?.property_manager_details?.image || user?.personalInfo?.image || '',
                mobile_verified : resp?.data?.property_manager_details?.mobile_verified ? true : false,
            })
            setStatus(resp?.data?.status);
            setSubsciption(resp?.data?.subscription || "Basic");
            setUserId(resp?.data?.user_id);
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }

    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])

    const getUser = async(userId, validation) => {
        try{
            if (userId) {
                const res = await getUserData(userId);
                setUser(res?.data);
                setFormData({
                    ...formData,
                    contact_person_name : formData?.contact_person_name || res?.data?.personalInfo?.fullName || '',
                    contact_person_number : formData?.contact_person_number || res?.data?.personalInfo?.mobile || '',
                    contact_person_email : formData?.contact_person_email || res?.data?.personalInfo?.email || '',
                    mobile_verified : validation ? true : formData?.mobile_verified,
                });
            }
        }catch(error){
            console.log(error)
        }
    }

    // for mobile verification start
    const handleMobileSuccess = () => {
        setShowVerifyMobile(false);
        getUser(userId, true);
        // setFormData({
        //     ...formData,
        //     mobile_verified : true,
        // });
        setErrors({
            ...errors,
            contact_person_number : "",
        });
    }

    const toggleMobile = () => {
        setShowVerifyMobile(!showVerifyMobile);
    };

    const verifyMobile = () => {
        if(!formData?.contact_person_number){
            setErrors({...errors,contact_person_number : 'Mobile number is required'});
            return null;
        }
        const error = validatePhoneNumber(formData?.contact_person_number);
        // console.log("verifyMobile error: ", error);
        if(error){
            setErrors({...errors, "contact_person_number" : error});
            return null;
        }
        toggleMobile();
    }

    const checkMobileValidity = () => {
        let validity = false;
        if ((user?.personalInfo?.mobile === formData?.contact_person_number) && (!(user?.personalInfo?.mobileVerified))) {
            validity = true;
        } else if (!(user?.personalInfo?.mobile)) {
            validity = true;
        } 
        return validity;
    }

    useEffect(()=>{
        if(userId){
            getUser(userId);
        }
    },[userId])
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Property Owner / Manager Details</span>
                        <div className='become_a_host_edit_profile_image_section' style={{backgroundImage : `url(${previewUrl || formData?.image || man})`}}>
                        </div>
                        <input
                            className='become_a_host_edit_profile_image_input'
                            id='imageUpload'
                            type="file"
                            accept=".webp, .webp, .jpeg"
                            onChange={onUploadImage}
                            ref={imageRef}
                        />
                        <span className='become_a_host_edit_profile_image_label' onClick={()=> imageRef.current.click()}>
                            <img src={addImage} />
                        </span>
                        <span className="content heading_color_1 mt" id="contact_person_designation">Contact Person managing this property{' '}<label className="required"></label></span>
                        <div className="d-flex flex-column w-100 mt">
                            {OWNERTYPES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name='contact_person_designation'
                                            checked={each === formData?.contact_person_designation}
                                            onChange={()=>onChange('contact_person_designation',each)}
                                        />
                                        <label htmlFor={each} className={`content text_color_4}`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.contact_person_designation && <span className="content text-danger">{errors?.contact_person_designation}</span>}
                        <div className="d-flex flex-column w-100 mt">
                            <label htmlFor='contact_person_name' className="content text_color_1">Contact Person Name{' '}<label className="required"></label></label>
                            <input
                                type="text"
                                className={errors?.contact_person_name ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                name="contact_person_name"
                                value={formData?.contact_person_name}
                                id="contact_person_name"
                                onChange={(e)=>onChange('contact_person_name',e.target.value)}
                                placeholder="Please enter a name"
                                autoComplete="off"
                            />
                            {errors?.contact_person_name && <span className="content text-danger">{errors?.contact_person_name}</span>}
                        </div>
                        <div className="become_a_host_CD_wrap">
                            {/* <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='contact_person_number' className="content text_color_1">Contact Number{' '}<label className="required"></label></label>
                                <input
                                    type="text"
                                    className={errors?.contact_person_number ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="contact_person_number"
                                    value={formData?.contact_person_number}
                                    id="contact_person_number"
                                    onChange={(e)=>onChange('contact_person_number',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.contact_person_number && <span className="content text-danger">{errors?.contact_person_number}</span>}
                            </div> */}
                            <div className="d-flex flex-row w-100 mt">
                                <MobileInput
                                    id='contact_person_number'
                                    label='Contact Number'
                                    name='contact_person_number'
                                    value={formData?.contact_person_number || ""}
                                    onChange={(e)=>onChange('contact_person_number',e.target.value)}
                                    placeholder='Enter number'
                                    validate={(value) => (value ? '' : 'Phone Number is required')}
                                    errorMessage={errors?.contact_person_number}
                                    required={true}
                                    title="Please enter a valid mobile number"
                                    disabled={formData?.mobile_verified ? true : false}
                                    styles={"become_a_host_text_input"}
                                />
                                {(((user?.personalInfo?.mobile !== formData?.contact_person_number) || (!user?.personalInfo?.mobileVerified)) && !(formData?.mobile_verified) ) && <button className='content btn btn-primary' type='button' style={{marginLeft : "-124px", marginTop : "24px", height: "45px"}} onClick={verifyMobile}><NewReleasesIcon/>Verify now</button>}
                                {(((user?.personalInfo?.mobile && user?.personalInfo?.mobileVerified) && (user?.personalInfo?.mobile === formData?.contact_person_number)) || formData?.mobile_verified) && <p className='edit_profile_verify content' style={{marginLeft : "-75px", marginTop : "35px"}}><VerifiedIcon/>Verified</p>}
                            </div>
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='contact_person_email' className="content text_color_1">Email Address{' '}<label className="required"></label></label>
                                <input
                                    type="text"
                                    className={errors?.contact_person_email ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="contact_person_email"
                                    value={formData?.contact_person_email}
                                    id="contact_person_email"
                                    onChange={(e)=>onChange('contact_person_email',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.contact_person_email && <span className="content text-danger">{errors?.contact_person_email}</span>}
                            </div>
                        </div>
                        <div className="d-flex flex-column w-100 mt">
                            <label htmlFor='alternate_person_name' className="content text_color_1">Alternate Person's Name</label>
                            <input
                                type="text"
                                className={errors?.alternate_person_name ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                name="alternate_person_name"
                                value={formData?.alternate_person_name}
                                id="alternate_person_name"
                                onChange={(e)=>onChange('alternate_person_name',e.target.value)}
                                placeholder="Please enter a name"
                                autoComplete="off"
                            />
                            {errors?.alternate_person_name && <span className="content text-danger">{errors?.alternate_person_name}</span>}
                        </div>
                        <div className="become_a_host_CD_wrap">
                            <div className="d-flex flex-column w-100 mt">
                                <MobileInput
                                    id='alternate_person_number'
                                    label='Phone Number'
                                    name='alternate_person_number'
                                    value={formData?.alternate_person_number || ""}
                                    onChange={(e)=>onChange('alternate_person_number',e.target.value)}
                                    placeholder='Enter number'
                                    // validate={(value) => (value ? '' : 'Phone Number is required')}
                                    errorMessage={errors?.alternate_person_number}
                                    styles={"become_a_host_text_input"}
                                    // required={true}
                                    // title="Please enter a valid mobile number"
                                />
                            </div>
                            {/* <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='alternate_person_number' className="content text_color_1">Contact Number{' '}<label className="required"></label></label>
                                <input
                                    type="text"
                                    className={errors?.alternate_person_number ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="alternate_person_number"
                                    value={formData?.alternate_person_number}
                                    id="alternate_person_number"
                                    onChange={(e)=>onChange('alternate_person_number',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.alternate_person_number && <span className="content text-danger">{errors?.alternate_person_number}</span>}
                            </div> */}
                            <div className="d-flex flex-column w-100 mt">
                                <label htmlFor='alternate_person_email' className="content text_color_1">Email Address{' '}</label>
                                <input
                                    type="text"
                                    className={errors?.alternate_person_email ? "become_a_host_text_input become_a_host_text_input_error" : "become_a_host_text_input"}
                                    name="alternate_person_email"
                                    value={formData?.alternate_person_email}
                                    id="alternate_person_email"
                                    onChange={(e)=>onChange('alternate_person_email',e.target.value)}
                                    placeholder="Enter number"
                                    autoComplete="off"
                                />
                                {errors?.alternate_person_email && <span className="content text-danger">{errors?.alternate_person_email}</span>}
                            </div>
                        </div>
                        <span className="content heading_color_1 mt" id="languages">What languages do you speak?{' '}<label className="required"></label></span>
                        <div className="d-flex flex-row flex-wrap w-100 mt">
                            {LANGUAGES?.map((each,index) => {
                                return(
                                    <div key={index} className="TP_check_box_wrap mb-2">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            id={each}
                                            name='languages'
                                            checked={formData?.languages?.includes(each)}
                                            onChange={()=>onChangeInArrey('languages',each)}
                                        />
                                        <label htmlFor={each} className={`content text_color_4}`}>{each}</label>
                                    </div>
                                )
                            })}
                        </div>
                        {errors?.languages && <span className="content text-danger">{errors?.languages}</span>}
                        {/* <span className="content heading_color_1 mt">Terms and Conditions</span>
                        <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='privacy_policy'
                                name='privacy_policy'
                                checked={formData?.privacy_policy === true}
                                disabled
                            />
                            <label htmlFor='privacy_policy' className={`content text_color_4}`}>I have read and agree to the privacy policy{' '}<label className="required"></label></label>
                        </div>
                        {errors?.privacy_policy && <span className="content text-danger">{errors?.privacy_policy}</span>} */}
                        {/* <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='terms_and_conditions'
                                name='terms_and_conditions'
                                checked={formData?.terms_and_conditions === true}
                                disabled
                            />
                            <label htmlFor='terms_and_conditions' className={`content text_color_4}`}>I agree to the Terms and Conditions{' '}<label className="required"></label></label>
                        </div>
                        {errors?.terms_and_conditions && <span className="content text-danger">{errors?.terms_and_conditions}</span>} */}
                        {/* <div className="TP_check_box_wrap mt w-100">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='sharing_information'
                                name='sharing_information'
                                checked={formData?.sharing_information}
                                disabled
                            />
                            <label htmlFor='sharing_information' className={`content text_color_4}`}>I Consent to SpotLet using my information{' '}<label className="required"></label></label>
                        </div>
                        {errors?.sharing_information && <span className="content text-danger">{errors?.sharing_information}</span>} */}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_9} alt="step_9" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Add contact information for the property owner or manager. Ensure smooth communication for inquiries, bookings, and listing updates.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>{status === 'Incomplete' ? 'Save & Continue' : 'Submit'}</button>
        </div>
        {showLoader && <FullPageLoader/>}
        {showVerifyMobile && (
            <VerifyAnyMobile 
                show={showVerifyMobile} 
                onHide={toggleMobile} 
                onSuccess={handleMobileSuccess} 
                mobile={formData?.contact_person_number} 
                disableInput={true} 
                editProfile={checkMobileValidity()} 
            />
        )}
        </>
    )
}
export default ContactDetails;