import { useState, useEffect , useRef } from 'react';
import ModalWrapper from '../ModalWrapper/ModalWrapper';
import MobileInput from '../../Inputs/MobileInput';
import NumberInput from '../../Inputs/NumberInput';
import bcrypt from "bcryptjs-react";
import { sendOtp1, updateUser } from '../../modules/apps/services/api';
import { validatePhoneNumber } from '../../modules/apps/Property/Validations';
import FullPageLoader from '../../modules/apps/Property/Pages/Loader';
const  VerifyAnyMobile = (props) => {
    // console.log("VerifyAnyMobile props", props);
    // const {notify, triggerLoader, validatePhoneNumber} = useAuth()
    const [tab, setTab] = useState('verifyMobile');
    const [formData, setFormData] = useState({mobile : props?.mobile || ''})
    const [errors, setErrors] = useState({});
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(60);
    const [loader, setLoader] = useState(false);
    const intervalRef = useRef(null);
    const runTIme = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setTime((prevState) => {
                if (prevState <= 1) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    return 0;
                }
                return prevState - 1;
            });
        }, 1000);
    }
    const changeTab = (tab) => {
        setTab(tab)
    }
    const onBackTab = () => {
        setTab('verifyMobile')
    }
    const onChange = (e) => {
        const {name,value} = e.target
        setFormData({
            ...formData,
            [name] : value,
        })
        if(value){
            setErrors({ ...errors, [name]: '' });
        }
    };
    
    async function validateOtpFunc(userOtp) {
        const isOk = await bcrypt.compare(userOtp, otp);
        return isOk ? '' : 'Invalid Code'
    }
    const validateForm = async () => {
        const newErrors = {
            mobile: formData.mobile ? validatePhoneNumber(formData.mobile) : 'Mobile is required',
            otp: tab === 'validateOtp' ? formData?.otp ? await validateOtpFunc(formData?.otp) : 'Code is required' : '',
        };
        setErrors(newErrors);
    
        // Return false if there are errors
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById('modal_'+ key);
                if (inputElement) {
                    inputElement.scrollIntoView({ behavior: 'smooth' });
                }
                return false
            }
        }
        return true
    };
    const onSubmit = async (e) => {
        e?.preventDefault()
        if (! await validateForm()) {
            return null
        }
        setLoader(true)
        try{
            let resp
            switch (tab) {
                case 'verifyMobile':
                    resp = await sendOtp1(formData)
                    setOtp(resp?.data?.otp)
                    changeTab('validateOtp')
                    setTime(60)
                    runTIme()
                    break;
                case 'validateOtp':
                    // if (props?.editProfile) {
                    //     resp = await updateUser({...formData, mobileVerified : true});
                    // }
                    props.onSuccess();
                    break;
                default:
                    console.log('success');
                    break;
            }
            alert(resp?.data?.message || "verified");
        }catch(error){
            switch (error?.response?.data?.type) {
                case 'server':
                    alert(error?.response?.data?.error);
                    break;
                case 'email':
                    setErrors({email : error?.response?.data?.error});
                    break;
                default:
                    alert('Error while validating data');
                    break;
            }
        }
        setLoader(false) 
    }
    const resendCode = async () => {
        if(time !== 0){
            return null
        }
        setLoader(true)
        try{
            let resp = await sendOtp1(formData)
            setFormData({
                ...formData,
                otp : ''
            })
            setErrors({})
            alert(resp?.data?.message);
            setOtp(resp?.data?.otp);
            setTime(60);
            runTIme();
        }catch(error){
            alert(error?.response?.data?.error || 'Error while sending otp');
        }
        setLoader(false)
    }

    const verifyMobile = () => {
        return(
            <form onSubmit={onSubmit}>
                <div className="mb-2">
                    <MobileInput
                        id='modal_mobile'
                        label='Mobile'
                        name='mobile'
                        value={formData?.mobile || ""}
                        onChange={onChange}
                        placeholder='Enter number'
                        validate={(value) => (value ? '' : 'Mobile number is required')}
                        errorMessage={errors?.mobile}
                        required={true}
                        title="Please enter a valid mobile number"
                        disabled={props?.disableInput ? true : false}
                    />
                </div>
                <button type='submit' className="btn btn-primary sp_button w-100">Get Code</button>
            </form>
        )
    }
    const validateOtp = () => {
        return(
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <span className="main_text heading_color_1 mb-4 text-center">Two-Factor Verification</span>
                <span className="content text_color_3 text-center mb-4">Enter the verification OTP we sent to</span>
                {!props?.disableInput && (
                    <span className="content heading_color_1 text-center mb-4">{formData?.mobile}{' '}<span className="content heading_color_3 cursor" onClick={onBackTab}>(Edit)</span></span>
                )}
                <form className="d-flex flex-column w-100 mb-4" onSubmit={onSubmit}>
                    <div className="mb-2">
                        <NumberInput
                            id='modal_otp'
                            label='Type your 4 digit security OTP'
                            name='otp'
                            value={formData?.otp || ""}
                            onChange={onChange}
                            placeholder='Enter 4 digit OTP'
                            validate={(value) => (value ? '' : 'Otp is required')}
                            errorMessage={errors?.otp}
                            required={true}
                            title="Please enter 4 digit security OTP"
                        />
                    </div>
                    <button type='submit' className="btn btn-primary sp_button w-100 mb-4">Verify</button>
                    <span className="text_color_3 text-center fw-semibold fs-6 mb-2 content">Didn’t get the OTP ? You can  <span className={`${time === 0 ? "heading_color_3" : "text_color_3"} fw-semibold content cursor`} onClick={resendCode}>Resend</span> the OTP{time !== 0 && (<span className="sub_text heading_color_3"> in {time}</span>)}</span>
                </form>
            </div>
        )
    }

    useEffect(() => {
        if (props?.disableInput) {
            setTab("validateOtp");
            onSubmit();
        }
    }, []);
    return(
        <ModalWrapper heading="Verify Mobile" props={props}>
            {tab==='verifyMobile' && verifyMobile()}
            {tab==='validateOtp' && validateOtp()}
            {loader && <FullPageLoader/>}
        </ModalWrapper>
    )
}
export default VerifyAnyMobile;