import { Button } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import logo from "../../../../../../src/_metronic/assets/images/Spotlet_V1_Watermark4.webp";

function ImageCropper(props) {
  const { imageToCrop, onImageCropped } = props;
  const [cropConfig, setCropConfig] = useState(undefined);
  const [imageRef, setImageRef] = useState();
  const [adjustOpen, setAdjustOpen] = useState(true);

  async function cropImage(crop) {
    if (imageRef && crop?.width && crop?.height) {
      const croppedImage = await getCroppedWatermarkedImage(
        imageRef,
        crop,
        "croppedImage.jpeg", // destination filename
        logo
      );

      onImageCropped(croppedImage);
    }
  }

  const getCroppedWatermarkedImage = (sourceImage, cropConfig, fileName, logo) => {
    // Creating the cropped image from the source image
    const canvas = document.createElement("canvas");
  
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
  
    // Set canvas size to the scaled cropped dimensions
    const outputWidth = cropConfig.width * scaleX;
    const outputHeight = cropConfig.height * scaleY;
  
    // Limit the maximum resolution to control file size
    const maxResolution = 1920; // Maximum width or height
    if (outputWidth > maxResolution || outputHeight > maxResolution) {
      const scalingFactor = maxResolution / Math.max(outputWidth, outputHeight);
      canvas.width = outputWidth * scalingFactor;
      canvas.height = outputHeight * scalingFactor;
    } else {
      canvas.width = outputWidth;
      canvas.height = outputHeight;
    }
  
    const ctx = canvas.getContext("2d");
  
    // Draw the cropped image to the canvas
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      canvas.width,
      canvas.height
    );
  
    return new Promise((resolve, reject) => {
      // Compress to reduce file size
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
          
          // Add watermark after compression
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            // Draw the watermark on top of the cropped image
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
            // Add watermark image (logo)
            const watermarkImg = new Image();
            watermarkImg.src = logo;  // Assuming logo is the URL of the watermark image

            watermarkImg.onload = () => {
              // Calculate the position based on the right 60% and top 60% of the canvas size
              const x = canvas.width * 0.75 - watermarkImg.width / 2;  // Right 60%, centering watermark
              const y = canvas.height * 0.75 - watermarkImg.height / 2; // Top 60%, centering watermark
            
              // Draw the watermark on the canvas
              ctx.drawImage(watermarkImg, x, y);
            
              // Compress again if needed
              canvas.toBlob(
                (watermarkedBlob) => {
                  if (!watermarkedBlob) {
                    reject(new Error("Canvas is empty after watermark"));
                    return;
                  }
                  watermarkedBlob.name = fileName;
            
                  // Create URL for the final watermarked image
                  const watermarkedImageUrl = window.URL.createObjectURL(watermarkedBlob);
                  resolve(watermarkedImageUrl); // Return watermarked image URL
                },
                "image/jpeg", // Use JPEG for better compression
                0.8 // Adjust compression quality (0.6-0.8 works well)
              );
            };
          };
        },
        "image/jpeg", // Use JPEG for better compression
        0.8 // Adjust compression quality (0.6-0.8 works well)
      );
    });
  }

  function onImageLoad(e) {
    setImageRef(e.target);
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    );

    setCropConfig(crop);
  }
  useEffect(() => {
    cropImage();
  }, [cropConfig]);

  return (
    <div>
      <div
        style={{ position: "relative", maxWidth: "400px", marginTop: "5px" }}
      >
        <ReactCrop
          src={imageToCrop}
          crop={cropConfig}
          ruleOfThirds
          onComplete={(cropConfig) => cropImage(cropConfig)}
          onChange={(cropConfig, percentCrop) => setCropConfig(percentCrop)}
          crossorigin="anonymous" // to avoid CORS-related problems
          aspect={16 / 9}
        >
          {adjustOpen && <img src={imageToCrop} alt="" onLoad={onImageLoad} />}
        </ReactCrop>
      </div>
    </div>
  );
}

ImageCropper.defaultProps = {
  onImageCropped: () => {},
};

export default ImageCropper;
