import "./ListYourSpaceNew.css"

import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty } from "../../services/api";
import { useState,useEffect } from "react";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_6 from "../Images/BecomeHost/step_6.png";
import CloseIcon from '@mui/icons-material/Close';
//images end
import { DigitsNumberValidation, numberValueValidation } from "../Validations";


const TYPE_OF_SPACES = [
    "Banquet Hall",
    "Conference Hall",
    "Dining Hall",
   "Open Party Space",
   "Rooftop Venue",
    "Auditorium",
    "Ballroom",
    "Garden/Outdoor Venue",
    "Terrace Venue",
    "Resort/Beach Venue",
    "Clubhouse",
    "Poolside Venue",
    "Event Lounge",
    "Studio Space",
    "Barn/Farm Venue",
    "Exhibition Hall",
    "Sports Arena",
    "Cultural Hall",
    "Theater Space",
    "Multi-Purpose Hall",
];
const TIME_OPTIONS = [
    { value: "00:00 AM", label: "00:00 AM" },
    { value: "01:00 AM", label: "01:00 AM" },
    { value: "02:00 AM", label: "02:00 AM" },
    { value: "03:00 AM", label: "03:00 AM" },
    { value: "04:00 AM", label: "04:00 AM" },
    { value: "05:00 AM", label: "05:00 AM" },
    { value: "06:00 AM", label: "06:00 AM" },
    { value: "07:00 AM", label: "07:00 AM" },
    { value: "08:00 AM", label: "08:00 AM" },
    { value: "09:00 AM", label: "09:00 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "01:00 PM", label: "01:00 PM" },
    { value: "02:00 PM", label: "02:00 PM" },
    { value: "03:00 PM", label: "03:00 PM" },
    { value: "04:00 PM", label: "04:00 PM" },
    { value: "05:00 PM", label: "05:00 PM" },
    { value: "06:00 PM", label: "06:00 PM" },
    { value: "07:00 PM", label: "07:00 PM" },
    { value: "08:00 PM", label: "08:00 PM" },
    { value: "09:00 PM", label: "09:00 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:59 PM", label: "11:59 PM" },
];
const IN_HOUSE_SERVICES = ['Florists / Decoration','DJ','Photography/Videography','Lighting','Sound/Audio Equipment','Furniture Rentals']
const EXTERNAL_HOUSE_SERVICES = ['Catering','Decoration','DJ','Photography/Videography','Lighting','Sound/Audio Equipment','Furniture Rentals']
const BASIC_VENUE = {
    type_of_space : '',
    size : '',
    seated : '',
    standing : '',
    air_conditioned : false,
    changing_rooms : false,
    restrooms : false,
}
const INITIAL_DATA = {
    venues : [BASIC_VENUE],
    per_plate : '',
    venue : '',
    per_plate_and_venue : '',
    per_plate_min_guest : '',
    veg_price : '',
    non_veg_price : '',
    morning_slot : '',
    evening_slot : '',
    fullday_booking : '',
    morning_start : '',
    morning_end : '',
    morning_price : '',
    evening_start : '',
    evening_end : '',
    evening_price : '',
    fullday_start : '',
    fullday_end : '',
    fullday_price : '',
    ot_price_per_hour : '',
    high_tea : '',
    high_tea_price : '',
    high_tea_min_guests : '',
    special_discount : '',
    in_house_services :[],
    external_services : [],
    in_house_alcohol : '',
    outside_alcohol : '',
    lodging : '',
    lodging_rooms : '',
    lodging_room_price : '',
    taxes_applicable : '',
    food_tax : '',
    venue_tax : '',
    lodging_tax : '',
    baraat : '',
    fire_crackers : '',
    sacred_rituals : '',
    over_night_events : '',
    cleaning_fee : '',
    security_deposit : "",
    custom_policies : '',

    full_refund : false,
    partial_refund : false,
    no_refund : false,
    full_refund_days : '',
    partial_refund_percentage : '',
    partial_refund_days : '',
    // no_refund_days : '',

    // restricted areas
    restricted_areas_availability : "",
    restricted_areas : "",

    // damage policies
    damage_policy : "",
    property_inspection : "",

    // standard policies start
    amenities_risk: "",
    risk_waiver: "",
    emergency_case: "",
    first_aid_kit: "",
    generator_backup: "",
    outdoor_smoking: "",
    indoor_smoking: "",
    equipment_usage: "",
}

const EventNew = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const [foodType,setFoodType] = useState('')
    const [showLoader,setShowLoader] = useState(false)
    const [formData,setFormData] = useState(INITIAL_DATA)
    const [errors,setErrors] = useState({})
    const addVenue = () => {
        setFormData({
            ...formData,
            venues : [
                ...formData.venues,
                BASIC_VENUE,
            ]
        })
    }
    const deleteVenue = (venueIndex) => {
        const updatedVenues = formData.venues.filter((_, index) => index !== venueIndex);
        setFormData({
          ...formData,
          venues: updatedVenues,
        });
    }
    const onVenueDetailsChange = (venueIndex,name,value) => {
        const updatedvenues = formData.venues.map((venue, index) => {
            if (index === venueIndex) {
                return { ...venue, [name]: value };
            }
            return venue
        });
    
        setFormData({
            ...formData,
            venues: updatedvenues,
        });
    }
    const onChange = (name,value) => {
        setFormData({
            ...formData,
            [name] : value,
            ...((name === "full_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "full_refund" && value === false ) &&  {"full_refund_days" : ""}),
            ...((name === "partial_refund" && value === true ) && { "no_refund" : false }),
            ...((name === "partial_refund" && value === false ) && {"partial_refund_percentage" : "", "partial_refund_days" : ""}),
            ...((name === "no_refund" && value === true ) && { "full_refund" : false, "full_refund_days" : "", "partial_refund" : false, "partial_refund_percentage" : "",  "partial_refund_days" : ""  }),
            ...((name === "restricted_areas_availability" && value === "No") && {"restricted_areas" : ""}),
        })
        if(value){
            setErrors({ 
                ...errors,
                [name]: '',
                ...((name === "restricted_areas_availability") && {"restricted_areas" : ''}),
                ...((name === "no_refund" || name === "full_refund" || name === "partial_refund") && {"cancellation" : ''}),
            });
        }
    }
    const onChangeInArrey = (name,value) => {
        setFormData((prevState) => {
            const updatedPolicies = prevState?.[name]?.includes(value)
              ? prevState?.[name]?.filter(policy => policy !== value)
              : [...prevState?.[name], value];
            
            return { ...prevState, [name]: updatedPolicies };
        });
    }
    const onBack = () => {
        if(bookingTypes?.stay){
            navigate(`/apps/property/stay/${location_id}`)
            return null
        }
        navigate(`/apps/property/amenities/${location_id}`)
    }
    const validateVenues = () => {
        for (let i = 0; i < formData.venues.length; i++) {
            const venue = formData.venues[i];
            if (!venue.type_of_space || !venue.size || !venue.seated || !venue.standing) {
              return 'Fill all the required fields in venues';
            }
            // if (validateNumber(venue.size) || DigitsNumberValidation(venue.seated,"",1,8) || DigitsNumberValidation(venue.standing,"",1,8)) {
            //     return 'Invalid data in the venues';
            // }
            if (validateNumber(venue?.size)) {
                return "Invalid size";
            }
            if (DigitsNumberValidation(venue?.seated, "", 1, 8)) {
                return "Invalid seated capacity"
            }
            if (DigitsNumberValidation(venue?.standing, "", 1, 8)) {
                return "Invalid standing capacity"
            }
          }
          return '';
    }
    function convertToMinutes(time) {
        const [hourMinute, period] = time.split(' ');
        let [hour, minute] = hourMinute.split(':').map(Number);
      
        // Convert to 24-hour format
        if (period === 'PM' && hour !== 12) hour += 12;
        if (period === 'AM' && hour === 12) hour = 0;
      
        return hour * 60 + minute; // Return total minutes
    }
    const validateTime = (checkIn,checkOut) => {
        if(!checkIn){
            return ''
        }
        const checkInMinutes = convertToMinutes(checkIn);
        const checkOutMinutes = convertToMinutes(checkOut);
        if (checkOutMinutes <= checkInMinutes) {
            return 'To time must be after From time';
        }else{
            return ''
        }
    }
    const validateNumber = (value) => {
        if (value && Number.isInteger(Number(value)) && parseInt(value) > 0) {
            return '';
        } else {
            return 'Enter valid data';
        }
    }
    const validateForm = () => {
        const newErrors = {
            venues : validateVenues(),
            pricing_structure : (formData?.per_plate || formData?.venue) ? '' : 'Selecting per plate or venue is required',
            per_plate_min_guest : formData?.per_plate ? !formData?.per_plate_min_guest ? 'Minimum guest is required' : DigitsNumberValidation(formData?.per_plate_min_guest,"Minimum guest",1,5) : '',
            veg_price : formData?.per_plate ? formData?.veg_price ? DigitsNumberValidation(formData?.veg_price,"Veg price",1,5) : 'Veg price is required' : '',
            non_veg_price : (formData?.per_plate && foodType === 'No, both vegetarian and non-vegetarian food are allowed') ? formData?.non_veg_price ? DigitsNumberValidation(formData?.non_veg_price,"Non-Veg",1,5) : 'Non-Veg price is required' : '',
            venue_booking : formData?.venue ? (formData?.morning_slot || formData?.evening_slot || formData?.fullday_booking) ? '' : 'Select minimum one slot' : '',

            morning_start : formData?.morning_slot ? formData?.morning_start ? '' : 'From time is required' : '',
            morning_end : formData?.morning_slot ? formData?.morning_end ? validateTime(formData?.morning_start,formData?.morning_end) : 'To time is required' : '',
            morning_price : formData?.morning_slot ? formData?.morning_price ? DigitsNumberValidation(formData?.morning_price,"Price",1,8) : 'Price is required' : '',

            evening_start : formData?.evening_slot ? formData?.evening_start ? '' : 'From time is required' : '',
            evening_end : formData?.evening_slot ? formData?.evening_end ? validateTime(formData?.evening_start,formData?.evening_end) : 'To time is required' : '',
            evening_price : formData?.evening_slot ? formData?.evening_price ? DigitsNumberValidation(formData?.evening_price,"Price",1,8) : 'Price is required' : '',

            fullday_start : formData?.fullday_booking ? formData?.fullday_start ? '' : 'From time is required' : '',
            fullday_end : formData?.fullday_booking ? formData?.fullday_end ? validateTime(formData?.fullday_start,formData?.fullday_end) : 'To time is required' : '',
            fullday_price : formData?.fullday_booking ? formData?.fullday_price ? DigitsNumberValidation(formData?.fullday_price,"Price",1,8) : 'Price is required' : '',

            high_tea_price : formData?.high_tea ? formData?.high_tea_price ? DigitsNumberValidation(formData?.high_tea_price,"",1,8) : 'Required' : '',
            high_tea_min_guests : formData?.high_tea ? formData?.high_tea_min_guests ? DigitsNumberValidation(formData?.high_tea_min_guests,"",1,5) : 'Required' : '',

            lodging_rooms : formData?.lodging === 'Yes, lodging is available' ? formData?.lodging_rooms ? DigitsNumberValidation(formData?.lodging_rooms,"Lodging room",1,5) : 'Required' : '',
            lodging_room_price : formData?.lodging === 'Yes, lodging is available' ? formData?.lodging_room_price ? DigitsNumberValidation(formData?.lodging_room_price,"Price",1,8) : 'Required' : '',

            ot_price_per_hour : formData?.ot_price_per_hour ? DigitsNumberValidation(formData?.ot_price_per_hour,"Price",1,8) : '',
            // food_tax : formData?.food_tax ? (formData?.food_tax < 0 || formData?.food_tax > 99) ? 'Discount should be 0 to 99' : '' : '',
            food_tax : formData?.food_tax ? numberValueValidation(formData?.food_tax, "Tax", 1, 99) : '',
            // venue_tax: formData?.venue_tax ? (formData?.venue_tax < 0 || formData?.venue_tax > 99) ? 'Discount should be 0 to 99' : '' : '',
            venue_tax: formData?.venue_tax ? numberValueValidation(formData?.venue_tax, "Tax", 1, 99) : '',
            // lodging_tax : formData?.lodging_tax ? (formData?.lodging_tax < 0 || formData?.lodging_tax > 99) ? 'Discount should be 0 to 99' : '' : '',
            lodging_tax : formData?.lodging_tax ? numberValueValidation(formData?.lodging_tax, "Tax", 1, 99) : '',
            // special_discount : formData?.special_discount ? (formData?.special_discount < 0 || formData?.special_discount > 99) ? 'Discount should be 0 to 99' : '' : '',
            special_discount : formData?.special_discount ? numberValueValidation(formData?.special_discount, "Discount", 1, 99) : '',
            cleaning_fee : formData?.cleaning_fee ? DigitsNumberValidation(formData?.cleaning_fee,"Cleaning fee",1,5) : '',
            security_deposit : formData?.security_deposit ? DigitsNumberValidation(formData?.security_deposit,"Security deposit",1,5) : '',

            cancellation : (formData?.full_refund||formData?.partial_refund|| formData?.no_refund) ? "" : 'Select minimum one cancellation policy',
            full_refund_days : formData?.full_refund === true ? formData?.full_refund_days ?  validateNumber(formData?.full_refund_days) : 'Add days' : '',
            // partial_refund_percentage : formData?.partial_refund === true ? formData?.partial_refund_percentage ? (formData?.partial_refund_percentage < 0 || formData?.partial_refund_percentage > 99) ? 'Percentage should be 0 to 99' : '' : 'Add percentage' : '',
            partial_refund_percentage : formData?.partial_refund === true ? formData?.partial_refund_percentage ? numberValueValidation(formData?.partial_refund_percentage, "Refund percentage", 1, 99) : 'Add percentage' : '',
            partial_refund_days : formData?.partial_refund === true ? formData?.partial_refund_days ? validateNumber(formData?.partial_refund_days) : 'Add days' : '',
            // no_refund_days : formData?.no_refund === true ? formData?.no_refund_days ? validateNumber(formData?.no_refund_days) : 'Add hours' : '',

            //restricted areas
            restricted_areas : formData?.restricted_areas_availability === "Yes" ? formData?.restricted_areas ? "" : "enter some restricted areas"  : "",

        }
        // console.log("newErrors", newErrors);
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    function trimFormData(formData) {
        // Check if the formData is an object
        if (typeof formData === 'object' && formData !== null) {
          if (Array.isArray(formData)) {
            // If it's an array, loop through each element and trim spaces in strings
            return formData.map(trimFormData);
          } else {
            // If it's an object, loop through each key-value pair
            const trimmedObj = {};
            for (const key in formData) {
              if (formData.hasOwnProperty(key)) {
                trimmedObj[key] = trimFormData(formData[key]);
              }
            }
            return trimmedObj;
          }
        }
      
        // If it's a string, trim leading and trailing spaces
        if (typeof formData === 'string') {
          return formData.trim();
        }
      
        // If it's null or undefined, return as-is
        return formData;
    }
    const onSubmit = async () => {
        if(!validateForm()){
            // scrollToTop()
            return null
        }
        // console.log(formData)
        setShowLoader(true)
        try{
            const resp = await updateProperty({personal_corporate_events : trimFormData(formData)},location_id)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
            return null
        }
        if(bookingTypes?.film){
            navigate(`/apps/property/film/${location_id}`)
            return null
        }
        navigate(`/apps/property/description/${location_id}`)
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            if(resp.data?.personal_corporate_events?.venues?.length > 0){
                setFormData({
                    ...resp.data?.personal_corporate_events,
                })
            }
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
            setFoodType(resp?.data?.basic_info?.food_type,)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    // console.log(formData)
    function scrollToElementWithOffset(element, offset) {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])

    const onChange2 = (e, value) => {
        const {checked, name} = e?.target;
        setFormData({
            ...formData,
            [name] : checked ? value : "",
        })
    }

    const standardPolicies = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1 mt" id="standard_policies">Select the standard policies you wish to apply for your location</span>

                <span className="content heading_color_1 mt">Liability Waiver</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='liability_1'
                            name='amenities_risk'
                            checked={'Guests agree to use the property and its amenities at their own risk.' === formData?.amenities_risk}
                            onChange={(e)=>onChange2(e,'Guests agree to use the property and its amenities at their own risk.')}
                        />
                        <label htmlFor='liability_1' className={`content text_color_4`}>Guests agree to use the property and its amenities at their own risk.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-auto">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='liability_2'
                            name='risk_waiver'
                            checked={'The host is not liable for any injuries, accidents, or loss of personal belongings during the stay.' === formData?.risk_waiver}
                            onChange={(e)=>onChange2(e,'The host is not liable for any injuries, accidents, or loss of personal belongings during the stay.')}
                        />
                        <label htmlFor='liability_2' className={`content text_color_4`}>The host is not liable for any injuries, accidents, or loss of personal belongings during the stay.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Emergency Contact Information</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='emergency_1'
                            name='emergency_case'
                            checked={'In case of an emergency, guests should contact the host immediately.' === formData?.emergency_case}
                            onChange={(e)=>onChange2(e,'In case of an emergency, guests should contact the host immediately.')}
                        />
                        <label htmlFor='emergency_1' className={`content text_color_4`}>In case of an emergency, guests should contact the host immediately.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Safety & Health</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='health_1'
                            name='first_aid_kit'
                            checked={'First-Aid kit available on-site for minor injuries.' === formData?.first_aid_kit}
                            onChange={(e)=>onChange2(e,'First-Aid kit available on-site for minor injuries.')}
                        />
                        <label htmlFor='health_1' className={`content text_color_4`}>First-Aid kit available on-site for minor injuries.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='health_2'
                            name='generator_backup'
                            checked={'Generator Backup: Available in case of power outages.' === formData?.generator_backup}
                            onChange={(e)=>onChange2(e,'Generator Backup: Available in case of power outages.')}
                        />
                        <label htmlFor='health_2' className={`content text_color_4`}>Generator Backup: Available in case of power outages.</label>
                    </div>
                </div>

                <span className="content heading_color_1 mt">Smoking</span>
                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='smoking_1'
                            name='outdoor_smoking'
                            checked={'Smoking allowed in Outdoor areas only.' === formData?.outdoor_smoking}
                            onChange={(e)=>onChange2(e,'Smoking allowed in Outdoor areas only.')}
                        />
                        <label htmlFor='smoking_1' className={`content text_color_4`}>Smoking allowed in Outdoor areas only.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='smoking_2'
                            name='indoor_smoking'
                            checked={'Indoor Smoking is strictly prohibited. A cleaning fee may apply if indoor smoking is detected.' === formData?.indoor_smoking}
                            onChange={(e)=>onChange2(e,'Indoor Smoking is strictly prohibited. A cleaning fee may apply if indoor smoking is detected.')}
                        />
                        <label htmlFor='smoking_2' className={`content text_color_4`}>Indoor Smoking is strictly prohibited. A cleaning fee may apply if indoor smoking is detected.</label>
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-start align-items-start">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='equipment_usage'
                            name='equipment_usage'
                            checked={'Guests are encouraged to use equipment safely and responsibly.' === formData?.equipment_usage}
                            onChange={(e)=>onChange2(e,'Guests are encouraged to use equipment safely and responsibly.')}
                        />
                        <label htmlFor='equipment_usage' className={`content text_color_4`}>Guests are encouraged to use equipment safely and responsibly.</label>
                    </div>
                </div>
            </>
        )
    }

    const restrictedAreas = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1 mt">Restricted Areas</span>
                <span className="content text_color_1 mt">Are there any restricted areas on your property?</span>
                <div className="d-flex flex-row justify-content-start align-items-start w-100 flex-wrap">
                    <div className="TP_main_check_box_wrap mt mr-2 w-100 align-items-center flex-wrap">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='restricted_areas_yes'
                            name='restricted_areas_availability'
                            checked={'Yes' === formData?.restricted_areas_availability}
                            onChange={(e)=>onChange(e.target.name,'Yes')}
                        />
                        <label htmlFor='restricted_areas_yes' className={`content text_color_4`}>Yes (list areas) </label>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap sp_ml_1">
                            <textarea
                                rows={1.5}
                                columns={35}
                                id='restricted_areas'
                                name='restricted_areas'
                                value={formData?.restricted_areas}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                placeholder='Enter'
                                className='become_a_host_text_input mt-0'
                                disabled={!(formData?.restricted_areas_availability === "Yes")}
                            />
                        </div>
                    </div>
                    <div className="TP_main_check_box_wrap mt w-100">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='restricted_areas_no'
                            name='restricted_areas_availability'
                            checked={'No' === formData?.restricted_areas_availability}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='restricted_areas_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
                {errors?.restricted_areas && <span className="content text-danger"> {errors?.restricted_areas} </span>}
            </>
        )
    }

    const damagePolicy = () => {
        return(
            <>
                <hr className="w-100 mt-4 mb-4"/>
                <span className="content heading_color_1">Damage Policy</span>
                <span className="content text_color_1 mt">How do you handle damages?</span>
                <div className="d-flex flex-column justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt mr-2">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='damage_yes'
                            name='damage_policy'
                            checked={`Collect damage charges before guests leave the premises.` === formData?.damage_policy}
                            onChange={(e)=>onChange(e.target.name,`Collect damage charges before guests leave the premises.`)}
                        />
                        <label htmlFor='damage_yes' className={`content text_color_4`}>Collect damage charges before guests leave the premises.</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='damage_no'
                            name='damage_policy'
                            checked={'No, we do not collect any damage charges.' === formData?.damage_policy}
                            onChange={(e)=>onChange(e.target.name,'No, we do not collect any damage charges.')}
                        />
                        <label htmlFor='damage_no' className={`content text_color_4`}>No, we do not collect any damage charges.</label>
                    </div>
                </div>
                <span className="content text_color_1 mt">Do you conduct a property inspection at check-out?</span>
                <div className="d-flex flex-column flex-md-row justify-content-start align-items-start w-100">
                    <div className="TP_main_check_box_wrap mt sp_mr_1">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='property_inspection_yes'
                            name='property_inspection'
                            checked={'Yes' === formData?.property_inspection}
                            onChange={(e)=>onChange(e.target.name,'Yes')}
                        />
                        <label htmlFor='property_inspection_yes' className={`content text_color_4`}>Yes</label>
                    </div>
                    <div className="TP_main_check_box_wrap mt">
                        <input
                            type="checkbox"
                            className="become_a_host_check"
                            id='property_inspection_no'
                            name='property_inspection'
                            checked={'No' === formData?.property_inspection}
                            onChange={(e)=>onChange(e.target.name,'No')}
                        />
                        <label htmlFor='property_inspection_no' className={`content text_color_4`}>No</label>
                    </div>
                </div>
            </>
        ) 
    }
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Venue Information</span>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1" id="venues">Event Venue Type</span>
                        {errors?.venues && <span className="content text-danger">{errors?.venues}</span>}
                        {formData?.venues?.map((venue,venueIndex) => {
                            return(
                                <div key={venueIndex} className="become_a_host_stay_room_wrap mt">
                                    {formData?.venues?.length > 1 && (
                                        <div className="w-100 d-flex flex-row justify-content-end align-items-center">
                                            <CloseIcon className="cursor" onClick={()=>deleteVenue(venueIndex)}/>
                                        </div>
                                    )}
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Type of space : {' '}<label className="required"></label></span>
                                        <select
                                            className="price_input"
                                            name="type_of_space"
                                            value={venue?.type_of_space}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.value)}
                                        >
                                            <option value={''} selected disabled>select type</option>
                                            {TYPE_OF_SPACES?.map((each,index) => {
                                                return(
                                                    <option key={index} value={each} className="content text_color_1">{each}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Size(sq. ft) : {' '}<label className="required"></label></span>
                                        <input
                                            type="number"
                                            className="price_input"
                                            name="size"
                                            placeholder="Enter size"
                                            value={venue?.size}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.value)}
                                        />
                                    </div>
                                    <span className="content heading_color_1 mt">Guest Capacity</span>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Seated : {' '}<label className="required"></label></span>
                                        <input
                                            type="number"
                                            className="price_input"
                                            name="seated"
                                            placeholder="Enter seated"
                                            value={venue?.seated}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.value)}
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Standing : {' '}<label className="required"></label></span>
                                        <input
                                            type="number"
                                            className="price_input"
                                            name="standing"
                                            placeholder="Enter standing"
                                            value={venue?.standing}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.value)}
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Air Conditioned : </span>
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            name="air_conditioned"
                                            checked={venue?.air_conditioned}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.checked)}
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Changing Rooms : </span>
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            name="changing_rooms"
                                            checked={venue?.changing_rooms}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.checked)}
                                        />
                                    </div>
                                    <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <span className="content text_color_1 mr-2">Restrooms : </span>
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            name="restrooms"
                                            checked={venue?.restrooms}
                                            onChange={(e)=> onVenueDetailsChange(venueIndex,e.target.name,e.target.checked)}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className='w-100 mt'>
                            <button type="button" onClick={addVenue} className="btn btn-primary sp_button">Add Venue</button>
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Pricing</span>
                        {/* <span className="content heading_color_1 mt">Venue Capacity</span>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                            <span className="content text_color_1 mr-2">What is the maximum guest capacity for the venue? </span>
                            <input
                                type="number"
                                className="price_input"
                                name="capacity"
                                placeholder="Enter number"
                                checked={formData?.capacity}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                        </div> */}
                        <span className="content heading_color_1 mt" id="pricing_structure">Venue Pricing Structure</span>
                        <span className="content text_color_1 mt">How is the venue pricing structured for events?{' '}<label className="required"></label></span>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                name="per_plate"
                                id="per_plate"
                                checked={formData?.per_plate}
                                onChange={(e)=> onChange(e.target.name,e.target.checked)}
                            />
                            <label htmlFor="per_plate" className="content text_color_1 mr-2">Per Plate (based on guest count)</label>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                name="venue"
                                id="venue"
                                checked={formData?.venue}
                                onChange={(e)=> onChange(e.target.name,e.target.checked)}
                            />
                            <label htmlFor="venue" className="content text_color_1 mr-2">Venue Booking (flat rental rate)</label>
                        </div>
                        {/* <div className="d-flex flex-row justify-content-between align-items-center mt">
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                name="per_plate_and_venue"
                                id="per_plate_and_venue"
                                checked={formData?.per_plate_and_venue}
                                onChange={(e)=> onChange(e.target.name,e.target.checked)}
                            />
                            <label htmlFor="per_plate_and_venue" className="content text_color_1 mr-2">Both mandatory (venue booking fee plus per plate pricing)</label>
                        </div> */}
                        {errors?.pricing_structure && <span className="content text-danger">{errors?.pricing_structure}</span>}
                        {formData?.per_plate && (
                            <>
                                <span className="content heading_color_1 mt">If you selected "Per Plate" or "Both mandatory," please provide per-plate pricing details:</span>
                                <div className="d-flex flex-row justify-content-start align-items-center flex-wrap mt w-100">
                                    <span className="content text_color_1 mr-2" id="per_plate_min_guest">Minimum guest requirement for per-plate pricing:{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="per_plate_min_guest"
                                        placeholder="Enter number"
                                        value={formData?.per_plate_min_guest}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                </div>
                                {errors?.per_plate_min_guest && <span className="content text-danger">{errors?.per_plate_min_guest}</span>}
                                <div className="d-flex flex-row justify-content-start align-items-center flex-wrap mt w-100">
                                    <span className="content text_color_1 mr-2" id="veg_price">Starting Price per Plate (Veg):{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="veg_price"
                                        placeholder="Enter number"
                                        value={formData?.veg_price}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                </div>
                                {errors?.veg_price && <span className="content text-danger">{errors?.veg_price}</span>}
                                {foodType === 'No, both vegetarian and non-vegetarian food are allowed' && (
                                    <>
                                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt w-100">
                                            <span className="content text_color_1 mr-2">Starting Price per Plate (Non-Veg):{' '}<label className="required"></label></span>
                                            <input
                                                type="number"
                                                className="price_input"
                                                name="non_veg_price"
                                                placeholder="Enter number"
                                                value={formData?.non_veg_price}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            />
                                        </div>
                                        {errors?.non_veg_price && <span className="content text-danger">{errors?.non_veg_price}</span>}
                                    </>
                                )}
                            </>
                        )}

                        {formData?.venue && (
                            <>
                                <span className="content heading_color_1 mt">If you selected "Venue Booking" or "Both options available," please provide rental pricing details:</span>
                                <span className="content text_color_1 mt" id="venue_booking">Select available booking slot(s) and specify timing & price:</span>
                                {/* Morning slot start */}
                                <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        name="morning_slot"
                                        id='Morning Slot'
                                        checked={formData?.morning_slot}
                                        onChange={(e)=> onChange(e.target.name,e.target.checked)}
                                    />
                                    <label htmlFor='Morning Slot' className="content text_color_1 ml-2 cursor">Morning Slot</label>
                                </div>
                                {formData?.morning_slot && (
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="morning_start">From{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="morning_start"
                                                placeholder="Select time"
                                                value={formData?.morning_start}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >  
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.morning_start && <span className="content text-danger">{errors?.morning_start}</span>}
                                        </div>
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="morning_end">To{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="morning_end"
                                                placeholder="Select time"
                                                value={formData?.morning_end}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.morning_end && <span className="content text-danger">{errors?.morning_end}</span>}
                                        </div>
                                        <div className="mt w-100">
                                            <label htmlFor='morning_price' className="content text_color_1" id="morning_price">Price{' '}<label className="required"></label></label>
                                            <input
                                                type="number"
                                                className='become_a_host_text_input'
                                                name="morning_price"
                                                value={formData?.morning_price }
                                                id="morning_price"
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                                placeholder="Enter Price"
                                                autoComplete="off"
                                            />
                                            {errors?.morning_price && <span className="content text-danger">{errors?.morning_price}</span>}
                                        </div>
                                    </div>
                                )}
                                {/* Morning slot end */}



                                {/* Evening slot start */}
                                <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        name="evening_slot"
                                        id='Evening Slot'
                                        checked={formData?.evening_slot}
                                        onChange={(e)=> onChange(e.target.name,e.target.checked)}
                                    />
                                    <label htmlFor='Evening Slot' className="content text_color_1 ml-2 cursor">Evening Slot</label>
                                </div>
                                {formData?.evening_slot && (
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="evening_start">From{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="evening_start"
                                                placeholder="Select time"
                                                value={formData?.evening_start}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >  
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.evening_start && <span className="content text-danger">{errors?.evening_start}</span>}
                                        </div>
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="evening_end">To{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="evening_end"
                                                placeholder="Select time"
                                                value={formData?.evening_end}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.evening_end && <span className="content text-danger">{errors?.evening_end}</span>}
                                        </div>
                                        <div className="mt w-100">
                                            <label htmlFor='evening_price' className="content text_color_1" id="evening_price">Price{' '}<label className="required"></label></label>
                                            <input
                                                type="number"
                                                className='become_a_host_text_input'
                                                name="evening_price"
                                                value={formData?.evening_price }
                                                id="evening_price"
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                                placeholder="Enter Price"
                                                autoComplete="off"
                                            />
                                            {errors?.evening_price && <span className="content text-danger">{errors?.evening_price}</span>}
                                        </div>
                                    </div>
                                )}
                                {/* Evening slot end */}



                                {/* Fullday Booking start */}
                                <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        name="fullday_booking"
                                        id='Fullday Booking'
                                        checked={formData?.fullday_booking}
                                        onChange={(e)=> onChange(e.target.name,e.target.checked)}
                                    />
                                    <label htmlFor='Fullday Booking' className="content text_color_1 ml-2 cursor">Fullday Booking</label>
                                </div>
                                {formData?.fullday_booking && (
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-start w-100">
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="fullday_start">From{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="fullday_start"
                                                placeholder="Select time"
                                                value={formData?.fullday_start}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >  
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.fullday_start && <span className="content text-danger">{errors?.fullday_start}</span>}
                                        </div>
                                        <div className="mt sp_mr_1 w-100">
                                            <span className="content text_color_1" id="fullday_end">To{' '}<label className="required"></label></span>
                                            <select
                                                className="become_a_host_text_input"
                                                name="fullday_end"
                                                placeholder="Select time"
                                                value={formData?.fullday_end}
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                            >
                                                <option value={''} selected disabled>Select time</option>
                                                {TIME_OPTIONS?.map((each,index) => {
                                                    return(
                                                        <option key={index} value={each.value} className="content text_color_1">{each.label}</option>
                                                    )
                                                })}
                                            </select>
                                            {errors?.fullday_end && <span className="content text-danger">{errors?.fullday_end}</span>}
                                        </div>
                                        <div className="mt w-100">
                                            <label htmlFor='fullday_price' className="content text_color_1" id="fullday_price">Price{' '}<label className="required"></label></label>
                                            <input
                                                type="number"
                                                className='become_a_host_text_input'
                                                name="fullday_price"
                                                value={formData?.fullday_price }
                                                id="fullday_price"
                                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                                                placeholder="Enter Price"
                                                autoComplete="off"
                                            />
                                            {errors?.fullday_price && <span className="content text-danger">{errors?.fullday_price}</span>}
                                        </div>
                                    </div>
                                )}
                                {/* Fullday Booking end */}
                                {errors?.venue_booking && <span className="content text-danger">{errors?.venue_booking}</span>}
                            </>
                        )}



                        <span className="content text_color_1 mt">Overtime Charges (if applicable)</span>
                        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                            <span className="content text_color_1 mr-2" id="ot_price_per_hour">Enter Price per hour</span>
                            <input
                                type="number"
                                className="price_input"
                                name="ot_price_per_hour"
                                placeholder="Enter number"
                                value={formData?.ot_price_per_hour}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                        </div>
                        {errors?.ot_price_per_hour && <span className="content text-danger">{errors?.ot_price_per_hour}</span>}

                        <span className="content text_color_1 mt">Is High Tea with Snacks available for events?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='high_tea_yes'
                                    name='high_tea'
                                    checked={formData?.high_tea === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='high_tea_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='high_tea_no'
                                    name='high_tea'
                                    checked={formData?.high_tea === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='high_tea_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        {formData?.high_tea && (
                            <div className="d-flex flex-row justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt mr-1 sp_mr_1">
                                    <span className="content text_color_1 mr-2" id="high_tea_price">Cost per person:{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="high_tea_price"
                                        placeholder="Enter number"
                                        value={formData?.high_tea_price}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.high_tea_price && <span className="content text-danger">{errors?.high_tea_price}</span>}
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                                    <span className="content text_color_1 mr-2" id="high_tea_min_guests">Minimum guests required:{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="high_tea_min_guests"
                                        placeholder="Enter number"
                                        value={formData?.high_tea_min_guests}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.high_tea_min_guests && <span className="content text-danger">{errors?.high_tea_min_guests}</span>}
                                </div>
                            </div>
                        )}
                        <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                            <span className="content text_color_1 mr-2" id="special_discount">Can you offer a special discount to attract more SpotLet users?</span>
                            <input
                                type="number"
                                className="price_input"
                                name="special_discount"
                                placeholder="Enter percentage"
                                value={formData?.special_discount}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {errors?.special_discount && <span className="content text-danger">{errors?.special_discount}</span>}
                        </div>
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Services Offered by Venue</span>
                        <span className="content mt">Which of the following services do you offer in-house?</span>
                        {IN_HOUSE_SERVICES?.map((each,index)=>{
                            return(
                                <>
                                    <div key={index} className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            name="in_house_services"
                                            id={'in_house_services_'+each}
                                            checked={formData?.in_house_services?.includes(each)}
                                            onChange={(e)=> onChangeInArrey(e.target.name,each)}
                                        />
                                        <label htmlFor={'in_house_services_'+each} className="content ml-2 cursor">{each}</label>
                                    </div>
                                </>
                            )
                        })}

                        <span className="content text_color_1 mt">Which types of external vendors are permitted at your venue locations?</span>
                        {EXTERNAL_HOUSE_SERVICES?.map((each,index)=>{
                            return(
                                <>
                                    <div key={index} className="d-flex flex-row justify-content-between align-items-center flex-wrap mt">
                                        <input
                                            type="checkbox"
                                            className="become_a_host_check"
                                            name="external_services"
                                            id={'external_services_' + each}
                                            checked={formData?.external_services?.includes(each)}
                                            onChange={(e)=> onChangeInArrey(e.target.name,each)}
                                        />
                                        <label htmlFor={'external_services_' + each} className="content ml-2 cursor">{each}</label>
                                    </div>
                                </>
                            )
                        })}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Alcohol Policy for the Venue</span>
                        <span className="content text_color_1 mt">Does the venue provide in-house alcohol?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='in_house_alcohol_yes'
                                    name='in_house_alcohol'
                                    checked={formData?.in_house_alcohol === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='in_house_alcohol_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='in_house_alcohol_no'
                                    name='in_house_alcohol'
                                    checked={formData?.in_house_alcohol === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='in_house_alcohol_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>

                        <span className="content text_color_1 mt">Is outside alcohol allowed with a permit? (Corkage fee may apply)</span>
                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='outside_alcohol_yes'
                                    name='outside_alcohol'
                                    checked={formData?.outside_alcohol === 'Yes, outside alcohol is allowed with a permit'}
                                    onChange={(e)=>onChange(e.target.name,'Yes, outside alcohol is allowed with a permit')}
                                />
                                <label htmlFor='outside_alcohol_yes' className={`content text_color_4`}>Yes, outside alcohol is allowed with a permit</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='outside_alcohol_no'
                                    name='outside_alcohol'
                                    checked={formData?.outside_alcohol === 'No, outside alcohol is not allowed'}
                                    onChange={(e)=>onChange(e.target.name,'No, outside alcohol is not allowed')}
                                />
                                <label htmlFor='outside_alcohol_no' className={`content text_color_4`}>No, outside alcohol is not allowed</label>
                            </div>
                        </div>

                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Lodging Options</span>
                        <span className="content heading_color_1 mt">Lodging Availability</span>
                        <span className="content text_color_1 mt">Does the venue offer lodging options for guests?</span>
                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='lodging_yes'
                                    name='lodging'
                                    checked={formData?.lodging === 'Yes, lodging is available'}
                                    onChange={(e)=>onChange(e.target.name,'Yes, lodging is available')}
                                />
                                <label htmlFor='lodging_yes' className={`content text_color_4`}>Yes, lodging is available</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='lodging_no'
                                    name='lodging'
                                    checked={formData?.lodging === 'No, lodging is not available'}
                                    onChange={(e)=>onChange(e.target.name,'No, lodging is not available')}
                                />
                                <label htmlFor='lodging_no' className={`content text_color_4`}>No, lodging is not available</label>
                            </div>
                        </div>
                        {formData?.lodging === 'Yes, lodging is available' && (
                            <div className="d-flex flex-row justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt mr-1 sp_mr_1">
                                    <span className="content text_color_1 mr-2" id="lodging_rooms">Total Rooms Available:{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="lodging_rooms"
                                        placeholder="Enter number"
                                        value={formData?.lodging_rooms}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.lodging_rooms && <span className="content text-danger">{errors?.lodging_rooms}</span>}
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                                    <span className="content text_color_1 mr-2" id="lodging_room_price">Average Room Price per Night:{' '}<label className="required"></label></span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="lodging_room_price"
                                        placeholder="Enter price"
                                        value={formData?.lodging_room_price}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.lodging_room_price && <span className="content text-danger">{errors?.lodging_room_price}</span>}
                                </div>
                            </div>
                        )}



                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Taxes</span>
                        <span className="content heading_color_1 mt">Applicable Taxes</span>
                        <span className="content text_color_1 mt">Are there taxes applicable to lodging, food, or venue rental at this property?</span>
                        <div className="d-flex flex-column flex-md-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='taxes_applicable_yes'
                                    name='taxes_applicable'
                                    checked={formData?.taxes_applicable === 'Yes, taxes apply'}
                                    onChange={(e)=>onChange(e.target.name,'Yes, taxes apply')}
                                />
                                <label htmlFor='taxes_applicable_yes' className={`content text_color_4`}>Yes, taxes apply</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='taxes_applicable_no'
                                    name='taxes_applicable'
                                    checked={formData?.taxes_applicable === 'No, no taxes apply'}
                                    onChange={(e)=>onChange(e.target.name,'No, no taxes apply')}
                                />
                                <label htmlFor='taxes_applicable_no' className={`content text_color_4`}>No, no taxes apply</label>
                            </div>
                        </div>
                        {formData?.taxes_applicable === 'Yes, taxes apply' && (
                            <div className="d-flex flex-column justify-content-between align-items-start flex-wrap">
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt mr-1">
                                    <span className="content text_color_1 mr-2" id="food_tax">Food & Beverage Tax Rate:</span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="food_tax"
                                        placeholder="Enter percentage"
                                        value={formData?.food_tax}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.food_tax && <span className="content text-danger">{errors?.food_tax}</span>}
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                                    <span className="content text_color_1 mr-2" id="venue_tax">Venue Rental Tax Rate:</span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="venue_tax"
                                        placeholder="Enter percentage"
                                        value={formData?.venue_tax}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.venue_tax && <span className="content text-danger">{errors?.venue_tax}</span>}
                                </div>
                                <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                                    <span className="content text_color_1 mr-2" id="lodging_tax">Lodging Tax Rate:</span>
                                    <input
                                        type="number"
                                        className="price_input"
                                        name="lodging_tax"
                                        placeholder="Enter percentage"
                                        value={formData?.lodging_tax}
                                        onChange={(e)=> onChange(e.target.name,e.target.value)}
                                    />
                                    {errors?.lodging_tax && <span className="content text-danger">{errors?.lodging_tax}</span>}
                                </div>
                            </div>
                        )}

                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Additional Venue Policies</span>
                        <span className="content heading_color_1 mt">Additional Policies</span>

                        <span className="content text_color_1 mt">Are Baraat Processions Allowed?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='baraat_yes'
                                    name='baraat'
                                    checked={formData?.baraat === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='baraat_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='baraat_no'
                                    name='baraat'
                                    checked={formData?.baraat === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='baraat_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        <span className="content text_color_1 mt">Are Firecrackers Permitted?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='fire_crackers_yes'
                                    name='fire_crackers'
                                    checked={formData?.fire_crackers === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='fire_crackers_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='fire_crackers_no'
                                    name='fire_crackers'
                                    checked={formData?.fire_crackers === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='fire_crackers_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        <span className="content text_color_1 mt">Is the Sacred Fire Ritual (Hawan) Allowed?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='sacred_rituals_yes'
                                    name='sacred_rituals'
                                    checked={formData?.sacred_rituals === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='sacred_rituals_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='sacred_rituals_no'
                                    name='sacred_rituals'
                                    checked={formData?.sacred_rituals === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='sacred_rituals_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>
                        <span className="content text_color_1 mt">Are Overnight Events Allowed?</span>
                        <div className="d-flex flex-row justify-content-start align-items-start w-100">
                            <div className="TP_main_check_box_wrap mt sp_mr_1">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='over_night_events_yes'
                                    name='over_night_events'
                                    checked={formData?.over_night_events === true}
                                    onChange={(e)=>onChange(e.target.name,true)}
                                />
                                <label htmlFor='over_night_events_yes' className={`content text_color_4`}>Yes</label>
                            </div>
                            <div className="TP_main_check_box_wrap mt">
                                <input
                                    type="checkbox"
                                    className="become_a_host_check"
                                    id='over_night_events_no'
                                    name='over_night_events'
                                    checked={formData?.over_night_events === false}
                                    onChange={(e)=>onChange(e.target.name,false)}
                                />
                                <label htmlFor='over_night_events_no' className={`content text_color_4`}>No</label>
                            </div>
                        </div>

                        {/* restricted areas start */}
                        {restrictedAreas()}
                        {/* restricted areas end */}

                        {/* damage polcy start */}
                        {damagePolicy()}
                        {/* damage polcy end */}

                        {/* standard policies start */}
                        {standardPolicies()}
                        {/* standard policies end */}

                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="sub_text heading_color_1">Other Services & Charges</span>
                        <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                            <span className="content text_color_1 mr-2" id="cleaning_fee">Any Cleaning Charges applicable:</span>
                            <input
                                type="number"
                                className="price_input"
                                name="cleaning_fee"
                                placeholder="Enter price"
                                value={formData?.cleaning_fee}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {errors?.cleaning_fee && <span className="content text-danger">{errors?.cleaning_fee}</span>}
                        </div>
                        <div className="d-flex flex-column justify-content-start align-items-start flex-wrap mt">
                            <span className="content text_color_1 mr-2" id="security_deposit">Do you charge any Security Deposit:</span>
                            <input
                                type="number"
                                className="price_input"
                                name="security_deposit"
                                placeholder="Enter price"
                                value={formData?.security_deposit}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {errors?.security_deposit && <span className="content text-danger">{errors?.security_deposit}</span>}
                        </div>

                        {/* Cancellation Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1" id="cancellation">Cancellation Policy{' '}<span className="required"></span></span>
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='full_refund_yes'
                                name='full_refund'
                                checked={formData?.full_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.full_refund ? false : true)}
                            />
                            <label htmlFor='full_refund_yes' className={`content text_color_4`}>Full refund for cancellations made up to{' '}
                            <input
                                id="full_refund_days"
                                type="number"
                                className="price_input"
                                name="full_refund_days"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.full_refund_days}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                                {' '}days before the stay.</label>
                        </div>
                        {errors?.full_refund_days && <span className="content text-danger">{errors?.full_refund_days}</span>}
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='partial_refund_yes'
                                name='partial_refund'
                                checked={formData?.partial_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.partial_refund ? false : true)}
                            />
                            <label htmlFor='partial_refund_yes' className={`content text_color_4`}>Partial refund of{' '}
                            <input
                                id="partial_refund_percentage"
                                type="number"
                                className="price_input"
                                name="partial_refund_percentage"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.partial_refund_percentage}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {' '}% if canceled within{' '}
                            <input
                                id="partial_refund_days"
                                type="number"
                                className="price_input"
                                name="partial_refund_days"
                                placeholder=""
                                style={{width : '46px'}}
                                value={formData?.partial_refund_days}
                                onChange={(e)=> onChange(e.target.name,e.target.value)}
                            />
                            {' '}days before check-in.
                            </label>
                        </div>
                        <div className="d-flex flex-row justify-content-start align-items-start" style={{gap : '5px'}}>
                            {errors?.partial_refund_percentage && <span className="content text-danger">{errors?.partial_refund_percentage}</span>}
                            {errors?.partial_refund_days && <span className="content text-danger">{errors?.partial_refund_percentage &&'and'}</span>}
                            {errors?.partial_refund_days && <span className="content text-danger">{errors?.partial_refund_days}</span>}
                        </div>
                        <div className="TP_main_check_box_wrap mt mr-2" style={{alignItems : 'center'}}>
                            <input
                                type="checkbox"
                                className="become_a_host_check"
                                id='no_refund_yes'
                                name='no_refund'
                                checked={formData?.no_refund}
                                onChange={(e)=>onChange(e.target.name,formData?.no_refund ? false : true)}
                            />
                            <label htmlFor='no_refund_yes' className={`content text_color_4`}>No refund 
                                {/*for cancellations made less than{' '} */}
                                {/* <input
                                    type="number"
                                    className="price_input"
                                    name="no_refund_days"
                                    placeholder=""
                                    style={{width : '46px'}}
                                    value={formData?.no_refund_days}
                                    onChange={(e)=> onChange(e.target.name,e.target.value)}
                                />
                                {' '}hours before check-in. */}
                            </label>
                        </div>
                        {/* {errors?.no_refund_days && <span className="content text-danger">{errors?.no_refund_days}</span>} */}
                        {errors?.cancellation && <span className="content text-danger">{errors?.cancellation}</span>}
                        {/* Cancellation Policy end */}

                        {/* Custom Policy start */}
                        <hr className="w-100 mt-4 mb-4"/>
                        <span className="content heading_color_1">Do you have any specific policies to mention?</span>
                        <textarea
                            rows={4}
                            columns={55}
                            id='custom_policies'
                            name='custom_policies'
                            value={formData?.custom_policies}
                            onChange={(e)=> onChange(e.target.name,e.target.value)}
                            placeholder='Enter your custom policies'
                            className='become_a_host_text_input mt'
                            />
                        {/* Custom Policy end */}
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_6} alt="step_6" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">Add event-specific details like spaces, pricing, catering, and other services. Mention lodging options, policies, and advance payment terms for seamless event planning.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" className="btn btn-primary sp_button" onClick={onSubmit}>Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default EventNew;