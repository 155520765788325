import React, { useState, useEffect } from 'react';
// import ToolTip from '../Utils/ToolTip';
import { countryPhoneCodes } from '../modules/apps/Property/Validations';
import "./Inputs.css";

const MobileInput = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  validate,
  errorMessage,
  required,
  title,
  disabled,
  styles,
}) => {
  const [error, setError] = useState('');
  const [code, setCode] = useState('+91');
  const handleBlur = () => {
    if (validate) {
      const validationError = validate(value);
      setError(validationError);
    }
  };
  const onChangeCode = (e) => {
    setCode(e.target.value)
    e.target.value = ''
    onChange(e)
  }
  const onChangeIn = (e) => {
    if(e.target.value === ''){
      e.target.value = ''
      onChange(e)
      return null
    }
    if(!e.target.value.includes(code)){
      e.target.value = code + e.target.value
      onChange(e)
    }
  }
  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if(value){
      const matchedCode = countryPhoneCodes.find(({ value: countryCode }) => value.toString().startsWith(countryCode));
      if (matchedCode) {
        setCode(matchedCode.value);
      }
    }
  }, [value]);

  return (
    <div className="sp_text_container">
      <label htmlFor={id} className='content font-weight-normal text_color_1'>{label || 'Label'}
        {(required && label) && <span className='required'>{' '}</span>}
        {/* {(required && title) && (<ToolTip content={title}/>)} */}
      </label>
      <div className='w-100 d-flex flex-row justify-content-center align-items-center' style={{marginTop : "5px"}}>
        <select name={name} disabled={disabled || false} className='sp_input_tel cursor' value={code} onChange={onChangeCode}>
          {countryPhoneCodes?.map((each)=>{
            return(
              <option className='content' value={each?.value}>{each?.value}</option>
            )
          })}
        </select>
        <input
          type='tel'
          id={id}
          name={name}
          value={value.replace(code, '')}
          onChange={onChangeIn}
          onBlur={handleBlur}
          placeholder={placeholder || "Enter Value"}
          className={`${styles ? `${styles} mt-0` : "sp_input"} ${error && 'error'}`}
          title={title}
          disabled={disabled || false}
        />
      </div>
      <span className="content text-danger">{error}</span>
    </div>
  );
};

export default MobileInput;
