import { useEffect, useState } from "react";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import { generatePaymentLink,getProeprtiesList,getUsers,getSubscriptions,createPaymentOrder,saveSubscription,updatePaymentLink } from "../services/api";
import Select from 'react-select';

interface Window {
    Razorpay: any;
}
const Payment = () => {
    const [showLoader, setShowLoader] = useState<boolean>(false);

    //payament section start
    const [payData,setPayData] = useState<any>({})
    const [properties,setProperties] = useState<any>([])
    const [propertiesFiltered,setPropertiesFiltered] = useState<any>([])
    const [users,setUsers] = useState<any>([])
    const [plans,setPlans] = useState<any>([])
    const [selectedPorperty,setSelectedProperty] = useState<any>({label : '',value : ''})
    const [selectedUser,setSelectedUser] = useState<any>({label : '',value : ''})
    const [link,setLink] = useState<any>('')
    const [payShow,setPayShow] = useState<any>(false)
    const [errors,setErrors] = useState<any>({})
    const [filter,setFilter] = useState<any>('')
    const onChangePay = (e) => {
        const {name,value} = e.target
        setPayData({
            ...payData,
            [name] : value
        })
        setErrors({...errors, [name] : ''})
    }
    const onChangeType = (e) => {
        const {name,value} = e.target
        setPayData({
            [name] : value
        })
        setSelectedProperty({label : '',value : ''})
        setSelectedUser({label : '',value : ''})
        setLink('')
        const form : any = document.getElementById('admin_payment_form_data');
        if (form) {
            form.reset();
        }
        setErrors({...errors, [name] : ''})
    }
    function customRound(number) {
        let decimalPart = number % 1;
        if (decimalPart < 0.5) {
          return Math.floor(number);
        } else {
          return Math.ceil(number);
        }
    }
    const getTotalAmount = async (propPrevPlanPrice,propSelectedPrice,existingPlan) => {
        let AMOUNT : number = 0
        const SGST : number = 9
        const CGST : number = 9
        let resp : any = {};
        try{
            resp = await getSubscriptions({page : 1,items : 50, subscriptionPlan: existingPlan, searchTerm: payData?.property, status : '',sort : ''});
            console.log(resp.data.data?.[0])
        }catch(error){
            console.log(error)
        }
        let prevPlanPrice: number = propPrevPlanPrice;
        let standardPrice: number = propSelectedPrice;
        let perDayPrice: number = 0;
        let daysUsed: number = 0;
        let usedPrice: number = 0;
        let priceBalance: number = 0;
        let basePrice: number = 0;

        perDayPrice = parseFloat((prevPlanPrice / 365).toFixed(2));
        let toDayDate = new Date();
        let activationDate = new Date(resp.data.data?.[0]?.activationDate);

        // Calculating the time difference of two dates
        let Difference_In_Time = toDayDate.getTime() - activationDate.getTime();

        // Calculating the no. of days between two dates
        daysUsed = Math.round(Difference_In_Time / (1000 * 3600 * 24));
        usedPrice = parseFloat((daysUsed * perDayPrice).toFixed(2));
        priceBalance = parseFloat((prevPlanPrice - usedPrice).toFixed(2));
        basePrice = parseFloat((standardPrice - priceBalance).toFixed(2));

        let basicPrice: number = parseFloat(((basePrice * 100) / (100 + SGST + CGST)).toFixed(2));
        let sgst: number = parseFloat((basicPrice * (SGST / 100)).toFixed(2));
        let cgst: number = parseFloat((basicPrice * (CGST / 100)).toFixed(2));
        let totalAmount: number = parseFloat((basicPrice).toFixed(2)) + parseFloat((sgst).toFixed(2)) + parseFloat((cgst).toFixed(2));

        AMOUNT = parseFloat(customRound(totalAmount).toFixed(2));
        return AMOUNT
    }
    const onChangeSubscription = async (e) => {
        const {name,value} = e.target
        let AMOUNT : number = 0
        if(payData?.subscription_for === 'user'){
            switch (value) {
                case 'Flexible':
                    AMOUNT = 999
                    break;
                case 'Standard':
                    AMOUNT = 7499
                    break;
                case 'Premium':
                    AMOUNT = 9999
                    break;
                default:
                    break;
            }
        }else{
            const property = properties.find((property : any) => property.value === payData?.property)
            if(property?.subscription === 'Basic'){
                switch (value) {
                    case 'Flexible':
                        AMOUNT = 999
                        break;
                    case 'Standard':
                        AMOUNT = 7499
                        break;
                    case 'Premium':
                        AMOUNT = 9999
                        break;
                    default:
                        break;
                }
            }else if(property?.subscription === 'Flexible'){
                switch (value) {
                    case 'Standard':
                        AMOUNT = await getTotalAmount(999.00,7499.00,'Flexible')
                        break;
                    case 'Premium':
                        AMOUNT = await getTotalAmount(999.00,9999.00,'Flexible')
                        break;
                    default:
                        break;
                }
            }else if(property?.subscription === 'Standard'){
                switch (value) {
                    case 'Premium':
                      console.log('trgfdvb')
                        AMOUNT = await getTotalAmount(7499.00,9999.00,'Standard')
                        // AMOUNT = 9999
                        break;
                    default:
                        break;
                }
            }
        }
        console.log('AMOUNT',AMOUNT)
        setPayData({
            ...payData,
            [name]: value,
            amount : AMOUNT,
        })
        setErrors({...errors, [name] : ''})
    }
    const handleSelectUser = (selected : any) => {
        setSelectedUser(selected)
        setPayData({
            ...payData,
            user : selected?.value,
            subscription_plan : '',
            property : '',
            amount : '',
        })
        const subscription_plan : any = document.getElementById('subscription_plan');
        if (subscription_plan) subscription_plan.value = "";

        const amount : any = document.getElementById('amount');
        if (amount) amount.value = "";
        setSelectedProperty({label : '',value : ''})
        setPlans(['Flexible','Standard','Premium'])
        setErrors({...errors, user: ''})
    }
    const handleSelectProperty = (selected : any) => {
        setSelectedProperty(selected)
        setPayData({
            ...payData,
            property : selected?.value,
            subscription_plan : '',
            user : '',
            amount : '',
        })

        const subscription_plan : any = document.getElementById('subscription_plan');
        if (subscription_plan) subscription_plan.value = "";

        const amount : any = document.getElementById('amount');
        if (amount) amount.value = "";

        const property = properties.find((property : any) => property.value === selected?.value)
        switch (property?.subscription) {
            case 'Basic':
                setPlans(['Flexible','Standard','Premium'])
                break;
            case 'Flexible':
                setPlans(['Standard','Premium'])
                break;
            case 'Standard':
                setPlans(['Premium'])
                break;
            default:
                break;
        }
        setErrors({...errors, property : ''})
    }
    const DigitsNumberValidation = (value, error, min, max) => {
      const reqString = value?.toString();
      const regex = /^[0-9]+$/;
          if (!value) {
              return `${error} is required`;
          } else if (!regex.test(value)) {
              return `${error} can only contain numbers`;
          } else if (value < 1) {
              return `${error} should be greater than 1 characters`;
          } else if (min && reqString?.length < min) {
              return `${error} should be greater than ${min} characters`;
          } else if (max && reqString?.length > max) {
              return `${error} should be less than ${max} characters`;
          } 
          return '';
    }
    const charactersRequiredStringValidation = (value, error, min, max) => {
      const regex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
      const reqString = value?.toString().trim();
          if (!value) {
              return `${error} is required`;
          } else if (max && reqString?.length < min) {
              return `${error} should be greater than ${min} characters`;
          } else if (max && reqString?.length > max) {
              return `${error} should be less than ${max} characters`;
          } else if (!regex.test(reqString)) {
              return `${error} can only have letters and spaces`;
          }
           
          return '';
    }
      const validateContactNumber = (contactNumber: string): string => {
      const indianMobileNumberPattern = /^[6-9]\d{9}$/;
      if (!contactNumber) {
          return 'Please enter contact number';
      } else if (!indianMobileNumberPattern.test(contactNumber)) {
          return 'Please enter a valid mobile number';
      }
      return '';
    };
  
    const validateContactEmail = (contactEmail: string): string => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!contactEmail) {
          return 'Please enter contact email';
      } else if (!emailPattern.test(contactEmail)) {
          return 'Please enter a valid email address';
      }
      return '';
    };
    const validateForm = () => {
        const newErrors = {
            payment_for : !payData?.payment_for ? 'Please select payment for' : '',
            subscription_for : payData?.payment_for === 'Subscription' ? !payData?.subscription_for ? 'Please select subscription for' : '' : '',
            user : (payData?.payment_for === 'Subscription' && payData?.subscription_for === 'user') ? !payData?.user ? 'Please select user' : '' : '',
            property : (payData?.payment_for === 'Subscription' && payData?.subscription_for === 'property') ? !payData?.property ? 'Please select property' : '' : '',
            subscription_plan : payData?.payment_for === 'Subscription' ? !payData?.subscription_plan ? 'Please select subscription plan' : '' : '',
            booking_date : payData?.payment_for === 'Booking' ? !payData?.booking_date ? 'Please select booking date' : '' : '',
            amount : !payData?.amount ? 'Please enter amount' : DigitsNumberValidation(payData?.amount, "Amount", 1,5),
            billing_name : !payData?.billing_name ? 'Please enter billing name' : charactersRequiredStringValidation( payData?.billing_name,"Billing name",3,50),
            contact_number : !payData?.contact_number ? 'Please enter contact number' : validateContactNumber(payData?.contact_number),
            contact_email : !payData?.contact_email ? 'Please enter contact email' : validateContactEmail(payData?.contact_email),
        }
        console.log(newErrors)
        setErrors(newErrors);
        for (let key of Object.keys(newErrors)) {
            if (newErrors[key] !== '') {
                return false
            }
        }
        return true

    }
    const onSubmitPay = async(e) => {
        e.preventDefault()
        if(!validateForm()){
            return null
        }
        console.log(payData)
        setShowLoader(true)
        try{
            const resp = await generatePaymentLink(payData)
            console.log(resp)
            setLink(resp?.data?.data?.short_url)
            setPayShow(true)
        }catch(error : any){
            console.log(error)
            alert(error?.response?.data?.message)
        }
        setShowLoader(false)
    }
    const handlePayShow = () => {
        setPayData({})
        setSelectedProperty({label : '',value : ''})
        setSelectedUser({label : '',value : ''})
        setLink('')
        setPayShow(false)
        const form : any = document.getElementById('admin_payment_form_data');
        if (form) {
            form.reset();
        }
    }
    const onFilterChange = (value : any) => {
        setFilter(value)
        const filteredData : any = []
        properties.map((property : any) => {
            if(property?.status === value){
                filteredData.push({
                    value: property.value,
                    label: property.label,
                    status: property.status,
                    subscription: property.subscription,
                })
            }
        })
        setPropertiesFiltered(filteredData)
        setPayData({
            ...payData,
            property : '',
            subscription_plan : '',
            amount : '',
        })
        const subscription_plan : any = document.getElementById('subscription_plan');
        if (subscription_plan) subscription_plan.value = "";

        const amount : any = document.getElementById('amount');
        if (amount) amount.value = "";
        setSelectedProperty({label : '',value : ''})
    }
    const getSubscriptionsDetails = () => {
        const property = properties.find((property : any) => property.value === payData?.property)
        return (
            <div className="mt-2">
                <p>Existing Subscription : {property?.subscription}</p>
            </div>
        )
    }

    //razerpay start
    const onPay = () => {
        if(!validateForm()){
            return null
        }
        console.log(payData)
        displayRazorpay()
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
            resolve(true);
            };
            script.onerror = () => {
            resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        try {
            const res = await loadScript(
            'https://checkout.razorpay.com/v1/checkout.js'
            );
    
            if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
            }
            const result = await createPaymentOrder({
            amount: payData?.amount.toString(),
            currency: 'INR',
            });
            if (!result) {
            alert('Server error. Are you online?');
            return;
            }
            const { amount, id: order_id, currency } = result?.data;
            const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: amount,
            currency: currency,
            // "image":  logo,
            "theme": {
                "color": "#3e97ff"
            },
            name: 'SPOTLET SOLUTIONS PRIVATE LIMITED',
            description: `Payment for ${payData?.subscription_plan ? payData?.subscription_plan : null} ${payData?.payment_for}`,
            order_id: order_id,
            handler: async function (response : any) {
                // let newObj = {
                // ...payData,
                // orderId : order_id,
                // razorpayPaymentId: response.razorpay_payment_id,
                // razorpayOrderId: response.razorpay_order_id,
                // razorpaySignature: response.razorpay_signature,
                // status : 'Success',
                // }
                console.log('response',response)
                const data = {
                    paymentId: response.razorpay_payment_id,
                    paymentLink: response.razorpay_payment_link_id,
                    ReferenceId: response.razorpay_payment_link_reference_id,
                    status: response.razorpay_payment_link_status,
                    signature: response.razorpay_signature,
                }
            if(payData?.payment_for === 'Subscription'){
                await updatePaymentLink({...data,...payData})
                // await saveSubscription({userId: selectedSubscription?.userId,plan,newObj,billingAddress,subcriptionId : selectedSubscription?._id})
            }
            handlePayShow()
            alert('Payment successful');
            },
            prefill: {
            //   name: userProfile?.personalInfo?.firstName,
            //   email: userProfile?.personalInfo?.email,
            //   contact: userProfile?.personalInfo?.mobile,
            },
            notes: {
                address: '',
            },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (e) {
            console.log(e);
        }
        }
    //razerpay end

    useEffect(()=> {
        const getData = async() => {
            try{
                const resp = await getProeprtiesList('Approved')
                setProperties(resp?.data?.properties)
                setPropertiesFiltered(resp?.data?.properties)

                const response = await getUsers('user')
			    const data = response.data
                for (let i = 0; i < data.length; i++) {
                    const email = data[i]?.personalInfo?.email
					const username =  data[i]?.personalInfo?.fullName
					const mobile =  data[i]?.personalInfo?.mobile

                    const name = `${mobile}-${email}-${username}`
                    setUsers((prev) => {
                        return [...prev, { label: name, value: data[i]._id }];
                    })
                }
            }catch(error){
                console.log(error)
            }
        }
        getData()
    },[])
    const paymentSection = () => {
        return (
          <div className="card p-2 mt-3">
            <h1 className="mt-3"> Payment section</h1>
            <hr className="mt-1 mb-2" />
            <div className="d-flex flex-row justify-content-start align-items-center w-100">
              <form
                id="admin_payment_form_data"
                className="d-flex flex-column w-50"
                onSubmit={onSubmitPay}
              >
                <div className="d-flex flex-column w-100 mb-2">
                  <label className="fs-6 fw-semibold mb-2">
                    Payment for<label className="required"></label>
                  </label>
                  <select
                    name="payment_for"
                    id="payment_for"
                    onChange={onChangeType}
                    value={payData?.payment_for}
                    aria-label="Select a type"
                    data-control="select2"
                    data-placeholder="Select a type"
                    className="form-select form-select-solid"
                    data-dropdown-parent="#kt_modal_update_details"
                  >
                    <option value={""} selected hidden>
                      Select a type
                    </option>
                    <option value={"Booking"}>Booking</option>
                    <option value={"Subscription"}>Subscription</option>
                  </select>
                  {errors?.payment_for && (
                    <span className="text-danger">{errors?.payment_for}</span>
                  )}
                </div>
                {payData?.payment_for === "Subscription" && (
                  <div className="fv-row mb-7 mt-2">
                    <label className="fs-6 fw-semibold mb-2">
                      <span className="">
                        Subscription for<label className="text-danger"></label>
                      </span>
                    </label>
                    <div className="d-flex">
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={payData?.subscription_for === "user"}
                          id="subscription_for"
                          name="subscription_for"
                          onChange={(e) =>{
                            const subscription_plan : any = document.getElementById('subscription_plan');
                            if (subscription_plan) subscription_plan.value = "";
                            const amount : any = document.getElementById('amount');
                            if (amount) amount.value = "";
                            setSelectedProperty({label : '',value : ''})
                            setSelectedUser({label : '',value : ''})
                            setPayData({ ...payData, subscription_for: "user" })
                            setFilter('')
                          }}
                        />
                        <span className="form-check-label">User</span>
                      </label>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={payData?.subscription_for === "property"}
                          id="subscription_for"
                          name="subscription_for"
                          onChange={(e) =>{
                            const subscription_plan : any = document.getElementById('subscription_plan');
                            if (subscription_plan) subscription_plan.value = "";
                            const amount : any = document.getElementById('amount');
                            if (amount) amount.value = "";
                            setSelectedProperty({label : '',value : ''})
                            setSelectedUser({label : '',value : ''})
                            setPayData({ ...payData, subscription_for: "property" })
                            setFilter('')
                          }}
                        />
                        <span className="form-check-label">Property</span>
                      </label>
                    </div>
                    {errors?.subscription_for && (
                      <span className="text-danger">
                        {errors?.subscription_for}
                      </span>
                    )}
                  </div>
                )}
                {payData?.subscription_for === "property" && (
                  <div className="fv-row mb-7 mt-2">
                    <label className="fs-6 fw-semibold mb-2">
                      <span className="">Property status filter</span>
                    </label>
                    <div className="d-flex">
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filter === "Incomplete"}
                          id="filter"
                          name="filter"
                          onChange={(e) => onFilterChange("Incomplete")}
                        />
                        <span className="form-check-label">Incomplete</span>
                      </label>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filter === "Under Review"}
                          id="filter"
                          name="filter"
                          onChange={(e) => onFilterChange("Under Review")}
                        />
                        <span className="form-check-label">Under Review</span>
                      </label>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filter === "Verified"}
                          id="filter"
                          name="filter"
                          onChange={(e) => onFilterChange("Verified")}
                        />
                        <span className="form-check-label">Verified</span>
                      </label>
                      <label className="form-check form-check-sm form-check-custom form-check-solid me-5">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filter === "Approved"}
                          id="filter"
                          name="filter"
                          onChange={(e) => onFilterChange("Approved")}
                        />
                        <span className="form-check-label">Approved</span>
                      </label>
                    </div>
                    {errors?.subscription_for && (
                      <span className="text-danger">
                        {errors?.subscription_for}
                      </span>
                    )}
                  </div>
                )}
                {payData?.subscription_for === "user" && (
                  <div className="d-flex flex-column w-100 mb-2">
                    <label className="fs-6 fw-semibold mb-2">
                      User<label className="required"></label>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={selectedUser}
                      value={selectedUser}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name="user"
                      options={users}
                      onChange={handleSelectUser}
                    />
                    {errors?.user && (
                      <span className="text-danger">{errors?.user}</span>
                    )}
                  </div>
                )}
                {payData?.subscription_for === "property" && (
                  <div className="d-flex flex-column w-100 mb-2">
                    <label className="fs-6 fw-semibold mb-2">
                      Property<label className="required"></label>
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={selectedPorperty}
                      value={selectedPorperty}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name="property"
                      options={propertiesFiltered}
                      onChange={handleSelectProperty}
                    />
                    {errors?.property && (
                      <span className="text-danger">{errors?.property}</span>
                    )}
                  </div>
                )}
                
                {payData?.property && getSubscriptionsDetails()}

                {payData?.payment_for === "Subscription" && (
                  <div className="d-flex flex-column w-100 mb-2">
                    <label className="fs-6 fw-semibold mb-2">
                      Subscription Plan<label className="required"></label>
                    </label>
                    <select
                      id="subscription_plan"
                      name="subscription_plan"
                      onChange={onChangeSubscription}
                      value={payData?.subscription_plan}
                      aria-label="Select a plan"
                      data-control="select2"
                      data-placeholder="Select a plan"
                      className="form-select form-select-solid"
                      data-dropdown-parent="#kt_modal_update_details"
                    >
                      <option value={""} selected disabled hidden>
                        Select a plan
                      </option>
                      {plans?.map((plan: any) => (
                        <option value={plan}>{plan}</option>
                      ))}
                    </select>
                    {errors?.subscription_plan && (
                      <span className="text-danger">
                        {errors?.subscription_plan}
                      </span>
                    )}
                  </div>
                )}
                {payData?.payment_for === "Booking" && (
                  <>
                    <div className="d-flex flex-column w-100 mb-2">
                      <label className="fs-6 fw-semibold mb-2">
                        Booking Date<label className="required"></label>
                      </label>
                      <input
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        onChange={onChangePay}
                        className="form-control form-control-solid"
                        placeholder="Enter Date"
                        id="booking_date"
                        name="booking_date"
                        value={payData?.booking_date}
                      />
                    </div>
                    {errors?.booking_date && (
                      <span className="text-danger">
                        {errors?.booking_date}
                      </span>
                    )}
                  </>
                )}
                {/* <div className="d-flex flex-column w-100 mb-2">
                            <label className="fs-6 fw-semibold mb-2">Property Id<label className="required"></label></label>
                            <input type="text" required onChange={onChangePay} className="form-control form-control-solid" placeholder="Enter property id" name="property" value={payData?.property} />
                        </div> */}
                <div className="d-flex flex-column w-100 mb-2">
                  <label className="fs-6 fw-semibold mb-2">
                    Amount<label className="required"></label>
                  </label>
                  <input
                    disabled={payData?.payment_for === "Subscription"}
                    type="number"
                    onChange={onChangePay}
                    className="form-control form-control-solid"
                    placeholder="Enter Amount"
                    id="amount"
                    name="amount"
                    value={payData?.amount}
                  />
                  {errors?.amount && (
                    <span className="text-danger">{errors?.amount}</span>
                  )}
                </div>
                <div className="d-flex flex-column w-100 mb-2">
                  <label className="fs-6 fw-semibold mb-2">
                    Billing name<label className="required"></label>
                  </label>
                  <input
                    type="text"
                    onInput={onChangePay}
                    className="form-control form-control-solid"
                    placeholder="Enter Name"
                    id="billing_name"
                    name="billing_name"
                    value={payData?.billing_name}
                    pattern="[A-Za-z ]+"
                    title="Only alphabets and spaces are allowed"
                  />
                  {errors?.billing_name && (
                    <span className="text-danger">{errors?.billing_name}</span>
                  )}
                </div>
                <div className="d-flex flex-column w-100 mb-2">
                  <label className="fs-6 fw-semibold mb-2">
                    Contact Number<label className="required"></label>
                  </label>
                  <input
                    type="number"
                    // pattern="[6789][0-9]{9}"
                    onInput={onChangePay}
                    className="form-control form-control-solid"
                    placeholder="Enter Number"
                    id="contact_number"
                    name="contact_number"
                    value={payData?.contact_number}
                  />
                  {errors?.contact_number && (
                    <span className="text-danger">
                      {errors?.contact_number}
                    </span>
                  )}
                </div>
                <div className="d-flex flex-column w-100 mb-2">
                  <label className="fs-6 fw-semibold mb-2">
                    Contact Email<label className="required"></label>
                  </label>
                  <input
                    type="text"
                    // pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                    onInput={onChangePay}
                    className="form-control form-control-solid"
                    placeholder="Enter Email"
                    id="contact_email"
                    name="contact_email"
                    value={payData?.contact_email}
                  />
                  {errors?.contact_email && (
                    <span className="text-danger">{errors?.contact_email}</span>
                  )}
                </div>
                <div className="d-flex flex-row justify-content-end w-100">
                  <div className="align-self-end mt-2 mb-2">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={onPay}
                    >
                      {" "}
                      Pay now{" "}
                    </button>
                  </div>
                  <div className="align-self-end mt-2 mb-2 mx-2">
                    <button className="btn btn-primary" type="submit">
                      {" "}
                      Generate payment link{" "}
                    </button>
                  </div>
                </div>
              </form>
              <div className="d-flex flex-column w-50"></div>
            </div>
          </div>
        );
    }
    //payament section end

    return (
        <>
            {paymentSection()}
            <Modal show={payShow} onHide={() => handlePayShow()}>
                <Modal.Header closeButton>
                <Modal.Title>Payment Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Payment Link : <a target="_blank" href={link}>{link}</a></p>
                        <p>Expires in 20 min...</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => handlePayShow()}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
        </>

    );
};

export default Payment;
