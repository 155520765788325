import "./ListYourSpaceNew.css"
import { useNavigate, useLocation } from "react-router-dom";
import { getProperty,updateProperty, getPropertyTypes } from "../../services/api";
import FullPageLoader from "./Loader";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_1 from "../Images/BecomeHost/step_1.png";
import { useEffect, useState } from "react";
import { charactersRequiredStringValidation } from "../Validations";
//images end

const BOOKING_TYPES = [
    {
        name : 'Staycation/Homestay',
        value : 'stay'
    },
    {
        name : 'Corporate/Personal Events',
        value : 'event'
    },
    {
        name : 'Film Shooting',
        value : 'film'
    },
]

const TypeOfProperty = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    const location_id = segments?.[4]

    const location = useLocation();
    const id  = location?.state?.id || "";
    // console.log("type of property navigated id", id);

    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [status,setStatus] = useState('Incomplete')
    const [mainCategories, setMainCategories] = useState([]);
    const [propertyCategories, setPropertyCategories] = useState({});
    const [newType, setNewType] = useState("");
    const [error,setError] = useState('');
    const [formData,setFormData] = useState(
        {
            property_type : '',
            location_type : '',
            stay : null,
            film : null,
            event : null,

        }
    )
    const onSelectMain = (value) => {
        setFormData({
            ...formData,
            property_type : value,
            location_type : '',
        })
        setError('');
    }
    const onSelectType = (value) => {
        setFormData({
            ...formData,
            location_type: formData.location_type === value ? '' : value
        });
        setError('')
    }
    const validateType = (e,inputValue) => {
        e.preventDefault()
        // const regex = /^[a-zA-Z0-9 ]+$/;
        // if (!inputValue || inputValue.trim() === "") {
        //     setError("Type should not be empty or null.")
        //     return null
        // }
        // if (inputValue.length > 50) {
        //     setError("Type should not exceed 50 characters.")
        //     return null
        // }
        // if (!regex.test(inputValue)) {
        //     setError("Only alphabets are allowed.")
        //     return null
        // }
        const newTypeError = charactersRequiredStringValidation(inputValue, "Type", 3, 50);
        if (newTypeError) {
            setError(newTypeError);
            return null;
        }
        const lowerCaseInput = inputValue.toLowerCase();
        for (let key in propertyCategories) {
            if (propertyCategories[key].some(item => item.toLowerCase() === lowerCaseInput)) {
                setError(`${inputValue} is already present in "${key}"`)
                return null
            }
        }
        onSelectType(newType)
    }
    const onSelectBookingType = (name,value) => {
        setFormData({
            ...formData,
            [name] : value
        })
    }
    const onBack = () => {
        navigate('/')
    }
    const onSubmit = async () => {
        if(!formData?.location_type){
            alert('Please select one property type')
            return null
        }
        if(! (formData?.film || formData?.stay || formData?.event)){
            alert('Please select atleast one booking type')
            return null
        }
        if(location_id){
            try{
                const resp = await updateProperty(formData,location_id)
                navigate(`/apps/property/basic-information/${location_id}`)
            }catch(error){
                console.log(error)
            }
        }else{
            localStorage.setItem('tempListing',JSON.stringify(formData));
            navigate('/apps/property/basic-information', {state : {id}});
        }
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id)
            setFormData(
                {
                    property_type : resp?.data?.property_type || 'Residential Properties',
                    location_type : resp?.data?.location_type || '',
                    stay : resp?.data?.stay || null,
                    film : resp?.data?.film || null,    
                    event : resp?.data?.event || null,
                }
            )
            if(resp?.data?.property_type === 'Others'){
                setNewType(resp?.data?.location_type)
            }
            setStatus(resp?.data?.status)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    useEffect(()=>{
        if(location_id){
            getData()
            localStorage.removeItem('tempListing');
        }else{
            const storedData = localStorage.getItem('tempListing')
            if(storedData){
                let tempData = JSON.parse(storedData);
                setFormData(tempData);
            }
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    const getPageData = async() => {
        try{
            const response = await getPropertyTypes();
            setMainCategories([...response?.data?.main_categories]);
            setPropertyCategories({...response?.data?.property_categories});
        } catch(error) {
            console.log("error", error);
        }
    }
    useEffect(()=> {
        scrollToTop()
        getPageData();
    },[])
    return (
        <>
            <div className="become_a_host_wrap">
                <div className="become_a_host">
                    <div className="become_a_host_sub_wrap">
                        <div className="become_a_host_sub">
                            <div className="d-flex flex-row">
                                <span className="sub_text heading_color_1 mr-1">Type of Property</span>
                                <span className="sub_text text_color_1 required">(Select any one)</span>
                            </div>
                            <div className="TP_main_check_wrap mt-4">
                                {mainCategories?.map((each, index) => {
                                    return (
                                        <div key={index} className="TP_main_check_box_wrap mb-2">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id={each}
                                                name={each}
                                                checked={each === formData.property_type}
                                                onChange={() => onSelectMain(each)}
                                                disabled={status !== 'Incomplete'}
                                            />
                                            <label htmlFor={each} className={`content ${each === formData.property_type ? 'active_label' : 'text_color_4'}`}>{each}</label>
                                        </div>
                                    )
                                })}
                                <div className="TP_main_check_box_wrap mb-2">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='main_others'
                                        name='main_others'
                                        checked={'Others' === formData.property_type}
                                        onChange={() => onSelectMain('Others')}
                                        disabled={status !== 'Incomplete'}
                                    />
                                    <label htmlFor='main_others' className={`content ${'Others' === formData.property_type ? 'active_label' : 'text_color_4'}`}>Others</label>
                                </div>
                            </div>
                            <hr className="w-100 mt-4 mb-4" />
                            {formData?.property_type && (
                                <span className="sub_text heading_color_1 required">{formData?.property_type}</span>
                            )}
                            <div className="TP_check_wrap mt-4">
                                {propertyCategories?.[formData?.property_type]?.map((each, index) => {
                                    return (
                                        <div key={index} className="TP_check_box_wrap mb-4">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id={each}
                                                name={each}
                                                checked={each === formData.location_type}
                                                onChange={() => onSelectType(each)}
                                                disabled={status !== 'Incomplete'}
                                            />
                                            <label htmlFor={each} className={`content ${each === formData ? 'active_label' : 'text_color_4'}`}>{each}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            {(formData?.property_type === 'Others' && formData?.location_type === '') && (
                                <>
                                    <form className="d-flex flex-row" onSubmit={(e) => validateType(e, newType)}>
                                        <input placeholder="Add New Type" type="text" className="sp_input" onChange={(e) => {
                                            setNewType(e?.target?.value)
                                            setError('')
                                        }} />
                                        <button className="btn btn-primary sp_button ml-2 align-self-end" type="submit"> Submit </button>
                                    </form>
                                    {error && <span className="content text-danger">{error}</span>}
                                </>
                            )}
                            {(formData?.property_type === 'Others' && formData?.location_type) && (
                                <div className="TP_check_box_wrap mb-4">
                                    <input
                                        type="checkbox"
                                        className="become_a_host_check"
                                        id='sub_other'
                                        name='sub_other'
                                        checked={newType === formData.location_type}
                                        onChange={() => onSelectType(newType)}
                                        disabled={status !== 'Incomplete'}
                                    />
                                    <label htmlFor='sub_other' className={`content ${newType === formData ? 'active_label' : 'text_color_4'}`}>{newType}</label>
                                </div>
                            )}
                            <hr className="w-100 mt-4 mb-4" />
                            <span className="sub_text heading_color_1 required">What types of bookings are you open to?</span>
                            <div className="TP_main_check_wrap mt-4">
                                {BOOKING_TYPES?.map((each, index) => {
                                    return (
                                        <div key={index} className="TP_main_check_box_wrap mb-2">
                                            <input
                                                type="checkbox"
                                                className="become_a_host_check"
                                                id={each.value}
                                                name={each.value}
                                                checked={formData?.[each.value]}
                                                onChange={(e) => onSelectBookingType(each.value, e.target.checked)}
                                            />
                                            <label htmlFor={each.value} className={`content text_color_4`}>{each.name}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="become_a_host_sub_right_wrap">
                            <img src={step_1} alt="step_1" className="mb-2" />
                            <span className="content text_color_1 text-center mb-2">Define your property’s type and the experiences you offer. Select booking categories like staycations, events, or film shoots to tailor your property’s visibility.</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="become_a_host_footer">
                <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                    <img src={leftArrow} alt="leftArrow"/>
                    <span className="ml-2 sub_text text_color_1">Cancel</span>
                </div>
                <button type="button" onClick={onSubmit} className="btn btn-primary sp_button">Save & Continue</button>
            </div>
            {showLoader && <FullPageLoader/>}
        </>
    )
}
export default TypeOfProperty;