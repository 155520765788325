/* global google */
import React, { useState, useEffect, useRef } from 'react';

const Map = ({ handleLocationSelect }) => {
    const [location, setLocation] = useState({ lat: null, lng: null });
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const searchBoxRef = useRef(null);
    const [mapLoaded, setMapLoaded] = useState(false);

    // Load Google Maps API Script
    useEffect(() => {
        const loadScript = () => {
            if (!document.querySelector("script[src*='https://maps.googleapis.com/maps/api/js']")) {
                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBu9YP_o2Vl8Owd8iPYxLiImxD1tiObnV8&libraries=places`;
                script.async = true;
                script.onload = () => setMapLoaded(true); // Set mapLoaded to true after script is loaded
                script.onerror = () => console.error("Google Maps script failed to load.");
                document.head.appendChild(script);
            } else if (window.google) {
                setMapLoaded(true); // Script already loaded, mark as ready
            }
        };
        loadScript();
    }, []);

    // Initialize the map and other components when API is loaded
    useEffect(() => {
        if (!mapLoaded || !window.google || !mapRef.current) return;

        const map = new google.maps.Map(mapRef.current, {
            center: location,
            zoom: 17,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: true,
            fullscreenControl: false,
        });

        const marker = new google.maps.Marker({
            position: location,
            map: map,
            draggable: true,
            icon: {
                url: 'https://spotlet-files.s3.amazonaws.com/spotlet/images/mapMarker/SpotletMapLogo.png',
                scaledSize: new google.maps.Size(230, 90),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(115, 90),
            },
        });

        const infoWindow = new google.maps.InfoWindow({
            content: '<div><h3>Drag to exact location</h3></div>',
        });

        marker.addListener('click', () => {
            infoWindow.open(map, marker);
        });

        marker.addListener('dragend', (event) => {
            const newLocation = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            fetchAddress(newLocation);
        });

        const searchBox = new google.maps.places.SearchBox(searchBoxRef.current);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(searchBoxRef.current);

        searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces();
            if (places && places.length > 0) {
                const place = places[0];
                const newLocation = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                map.setCenter(newLocation);
                marker.setPosition(newLocation);
                fetchAddress(newLocation);
            }
        });

        markerRef.current = marker;

        return () => {
            google.maps.event.clearInstanceListeners(map);
            google.maps.event.clearInstanceListeners(marker);
        };
    }, [mapLoaded, location]);

    // Fetch address using Geocoder
    const fetchAddress = (location) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK && results[0]) {
                const placeId = results[0].place_id;
                handleLocationSelect(placeId);
                setLocation(location);
            } else {
                console.error('Geocoder failed: ', status);
            }
        });
    };

    return (
        <div className="sp_map_full">
            <div className="w-100">
                <input
                    ref={searchBoxRef}
                    type="text"
                    placeholder="Enter location/Pin/zip"
                    className="content"
                />
            </div>
            <div
                className={`${
                    location.lat && location.lng ? 'sp_map_container_active' : 'sp_map_container'
                }`}
                id="map"
                ref={mapRef}
            ></div>
        </div>
    );
};

export default React.memo(Map);
