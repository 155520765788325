import React, { useState, useEffect } from 'react';
// import ToolTip from '../Utils/ToolTip';
import "./Inputs.css"

const NumberInput = ({
  id,
  label,
  name,
  value,
  onChange,
  placeholder,
  validate,
  errorMessage,
  required,
  title,
  inputStyle,
  disabled,
}) => {
  const [error, setError] = useState('');

  const handleBlur = () => {
    if (validate) {
      const validationError = validate(value);
      setError(validationError);
    }
  };

  useEffect(() => {
    setError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    const handleWheel = (event) => {
      if (document.activeElement.type === 'number') {
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheel, { passive: false });

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);

  const handleKeyPress = (event) => {
    // Prevent 'e' and '.' from being typed
    if (event.key === 'e' || event.key === '.' || event.key === 'E') {
      event.preventDefault();
    }
  };

  const handleInput = (event) => {
    // Remove invalid characters from the input
    const inputValue = event.target.value;
    if (/[^0-9]/.test(inputValue)) {
      event.target.value = inputValue.replace(/[^0-9]/g, '');
      onChange(event); // Call onChange to ensure the parent component is updated
    }
  };

  return (
    <div className="sp_text_container">
      <label htmlFor={id} className='content font-weight-normal text_color_1'>
        {label}
        {(required && label) && <span className='required'>{' '}</span>}
        {/* {(required && title) && (<ToolTip content={title} />)} */}
      </label>
      <input
        type='number'
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onInput={handleInput}
        placeholder={placeholder || "Enter Value"}
        className={`sp_input ${error && 'error'}`}
        title={title}
        style={inputStyle}
        disabled={disabled || false}
      />
      <span className="content text-danger">{error}</span>
    </div>
  );
};

export default NumberInput;
