import { useEffect, useState } from "react";
import {Modal,Button} from 'react-bootstrap'
import { PageLoader } from "../services/PageLoader";
import { updateConvenienceFee, getConvenienceFee, fetchRequiredServiceData, updateRequiredServiceData } from "../services/api";
import { useAuth } from "../../auth";
import Select from 'react-select';

const WebAppControl = () => {
    const {currentUser} = useAuth()
    const [show,setShow] = useState<any>(false);
    const [modelBody,setModelBody] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        active : false,
        percentage : '',
        eligible : '',
    });
    const [googleServicesData,setGoogleServicesData] = useState<any>();
    const [bookingServicesData,setBookingServicesData] = useState<any>();
    const [smsServicesData,setSmsServicesData] = useState<any>();

    const getData = async () => {
        try{
            const resp = await getConvenienceFee()
            setFormData(resp?.data)
            setShowLoader(false)
        }catch(error){
            console.log(error)
            setShowLoader(false)
        }
    }

    const getGoogleControlData = async() => {
        try{
            const res = await fetchRequiredServiceData("GoogleServices");
            setGoogleServicesData(res?.data?.serviceData?.settings)
        }catch(error:any) {
            alert(error?.response?.data?.message)
        }
    }

    const getBookingsControlData = async() => {
        try{
            const res = await fetchRequiredServiceData("BookingServices");
            setBookingServicesData(res?.data?.serviceData?.settings)
        }catch(error:any) {
            alert(error?.response?.data?.message)
        }
    }
    
    const getSmsControlData = async() => {
        try{
            const res = await fetchRequiredServiceData("SMSServices");
            setSmsServicesData(res?.data?.serviceData?.settings)
        }catch(error:any) {
            alert(error?.response?.data?.message)
        }
    }

    const submitForm = async (e:any) => {
        e.preventDefault()
        setShowLoader(true)
        try{
            const resp = await updateConvenienceFee(formData)
            setFormData(resp?.data)
            getData()
            setModelBody(resp?.data)
            setShow(true)
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }

    useEffect(()=> {
        setShowLoader(true)
        getData();
        getGoogleControlData()
        getBookingsControlData()
        getSmsControlData()
    },[]);

    const onSettingsSubmit = async(e:any, name : any, data : any) => {
        e.preventDefault()
        setShowLoader(true)
        try{
            const res = await updateRequiredServiceData(name, data)
            alert(res?.data?.message)
        }catch(error:any) {
            console.log(error?.response?.data?.message)
            alert(error?.response?.data?.message)
        }
        setShowLoader(false)
    }

    const googleSection = () => {
        return(
            <>
                <form className="card p-2 mt-3"  onSubmit={(e) => onSettingsSubmit(e, "GoogleServices", googleServicesData)} >
                    <h1 className="mt-3"> Google Api Control </h1>
                    <hr className="mt-1 mb-2"/>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Google Maps for Listing</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Google Maps for Listing">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setGoogleServicesData({...googleServicesData,[e.target.name] : e.target.checked})} name="googleMapsListing" checked={googleServicesData?.googleMapsListing} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Google Maps for Property Page</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Google Maps for Property Page">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setGoogleServicesData({...googleServicesData,[e.target.name] : e.target.checked})} name="googleMapsPropertyPage" checked={googleServicesData?.googleMapsPropertyPage} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span> Google Reviews </span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Google Reviews">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setGoogleServicesData({...googleServicesData,[e.target.name] : e.target.checked})} name="googleReviews" checked={googleServicesData?.googleReviews} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Google Ratings</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Google Ratings">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setGoogleServicesData({...googleServicesData,[e.target.name] : e.target.checked})} name="googleRatings" checked={googleServicesData?.googleRatings} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="align-self-end mt-2 mb-2">
                        <button className="btn btn-primary" > Submit </button>
                    </div>
                </form>
            </>
        )
    }

    const bookingServicesSection = () => {
        return(
            <>
                <form className="card p-2 mt-3"  onSubmit={(e) => onSettingsSubmit(e, "BookingServices", bookingServicesData)} >
                    <h1 className="mt-3"> Booking Services Control </h1>
                    <hr className="mt-1 mb-2"/>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Film</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for film">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServicesData({...bookingServicesData,[e.target.name] : e.target.checked})} name="film" checked={bookingServicesData?.film} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Tv</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Tv">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServicesData({...bookingServicesData,[e.target.name] : e.target.checked})} name="tv" checked={bookingServicesData?.tv} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Event</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Event">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServicesData({...bookingServicesData,[e.target.name] : e.target.checked})} name="event" checked={bookingServicesData?.event} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Booking for Stay</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for Stay">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setBookingServicesData({...bookingServicesData,[e.target.name] : e.target.checked})} name="stay" checked={bookingServicesData?.stay} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="align-self-end mt-2 mb-2">
                        <button className="btn btn-primary" > Submit </button>
                    </div>
                </form>
            </>
        )
    }

    const smsServicesSection = () => {
        return(
            <>
                <form className="card p-2 mt-3"  onSubmit={(e) => onSettingsSubmit(e, "SMSServices", smsServicesData)} >
                    <h1 className="mt-3"> SMS Services Control </h1>
                    <hr className="mt-1 mb-2"/>
                    <div className="row mb-2">
                        <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span>Send SMS</span>
                                <span className="ms-1" data-bs-toggle="tooltip" title="Booking for film">
                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                        <span className="path3"></span>
                                    </i>
                                </span>
                        </label>
                        <div className="col-lg-8 d-flex align-items-center">
                            <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                                <input onChange={(e) => setSmsServicesData({...smsServicesData,[e.target.name] : e.target.checked})} name="sendSms" checked={smsServicesData?.sendSms} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                                <label className="form-check-label" htmlFor="active"></label>
                            </div>
                        </div>
                    </div>
                    <div className="align-self-end mt-2 mb-2">
                        <button className="btn btn-primary" > Submit </button>
                    </div>
                </form>
            </>
        )
    }

    return (
        <>
            <form className="card p-2" onSubmit={submitForm}>
                <div className="row mb-2">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        <span>Convenience Fee</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="Convenience fee on bookings">
                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                            <input onChange={(e) => setFormData({...formData,[e.target.name] : e.target.checked})} name="active" checked={formData?.active} className="form-check-input w-45px h-30px" type="checkbox" id="active" />
                            <label className="form-check-label" htmlFor="active"></label>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        <span>Percentage</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="Percentage accept only 0 to 100">
                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                            <input
                            onChange={(e)=> {
                                const regex = /^(?!-?[eE]$)([0-9]|[1-9][0-9]|100)$/;
                                if (e.target.value === "" || regex.test(e.target.value)) {
                                    setFormData({
                                    ...formData,
                                    [e.target.name] : e.target.value,
                                })
                                }
                            }}
                            disabled = {!formData.active}
                            required
                            name="percentage" value={formData?.percentage} type="number" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="value in %" id="percentage" />
                            <label className="form-check-label" htmlFor="percentage"></label>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <label className="col-lg-4 col-form-label fw-semibold fs-6">
                        <span>Maximum amount upto</span>
                            <span className="ms-1" data-bs-toggle="tooltip" title="Convenience fee is elible on bookings">
                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                    </label>
                    <div className="col-lg-8 d-flex align-items-center">
                        <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                            <input disabled = {!formData.active} required onChange={(e) => setFormData({...formData,[e.target.name] : e.target.value})} name='eligible' value={formData?.eligible} type="number" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="price" id="eligible" />
                            <label className="form-check-label" htmlFor="eligible"></label>
                        </div>
                    </div>
                </div>
                {currentUser?.permissions?.Payment_Management?.write && (<div className="card-footer d-flex justify-content-end py-6 px-9">
                    {/* <button type="reset" className="btn btn-light btn-active-light-primary me-2">Discard</button> */}
                    <button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit">Save Changes</button>
                </div>)}
            </form>
            {googleSection()}
            {bookingServicesSection()}
            {smsServicesSection()}
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                <Modal.Title>Payments</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modelBody}</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={() => setShow(false)}>
                    Ok
                </Button>
                </Modal.Footer>
            </Modal>
            {showLoader && <PageLoader />}
        </>

    );
};

export default WebAppControl;
