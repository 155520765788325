export const DigitsNumberValidation = (value, error, min, max) => {
    const reqString = value?.toString();
    const regex = /^[0-9]+$/;
        if (!value) {
            return `${error} is required`;
        } else if (!regex.test(value)) {
            return `${error} can only contain numbers`;
        } else if (value < 1) {
            return `${error} should be greater than 1 characters`;
        } else if (min && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const numberValueValidation = (value, error, min, max) => {
    const reqString = value.toString();
    if (value == null || reqString?.trim() === '') {
        return `${error} is required`;
    }
    const regex = /^[0-9]+$/;
    if (!regex.test(value)) {
        return `${error} can only contain numbers`;
    }
    const reqValue = parseInt(value, 10);
    if (isNaN(reqValue)) {
        return `${error} must be a valid number`;
    }
    if (reqValue < min || reqValue > max) {
        return `${error} should be ${min} to ${max}`;
    }
    return '';
};

export const notRequiredNumberValidation = (value, error, min, max) => {
    const reqString = value?.toString();
    const regex = /^[0-9]+$/;
        if (!regex.test(value)) {
            return `${error} can only contain numbers`;
        } else if (value < 1) {
            return `${error} should be greater than 1 characters`;
        } else if (min && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const charactersRequiredStringValidation = (value, error, min, max) => {
    const regex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
    const reqString = value?.toString().trim();
        if (!value) {
            return `${error} is required`;
        } else if (max && reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } else if (!regex.test(reqString)) {
            return `${error} can only have letters and spaces`;
        }
         
        return '';
}

export const charactersStringValidation = (value, error, min, max) => {
    const reqString = value?.toString();
        if (reqString?.length < min) {
            return `${error} should be greater than ${min} characters`;
        } else if (max && reqString?.length > max) {
            return `${error} should be less than ${max} characters`;
        } 
        return '';
}

export const charactersDigitsValidation = (value, error, min, max) =>{
    const reqString = value?.toString();
    const regex = /^[a-zA-Z0-9 ]+$/;
    if (!value) {
        return `${error} is required`;
    } else if (!regex.test(value)) {
        return `${error} can only contain alphabets and numbers`;
    } else if (min && reqString?.length < min) {
        return `${error} should be greater than ${min} characters`;
    } else if (max && reqString?.length > max) {
        return `${error} should be less than ${max} characters`;
    } 
    return '';
}

export const countryPhoneCodes = [
    { label: "Afghanistan", value: "+93", flag: "🇦🇫" },
    { label: "Algeria", value: "+213", flag: "🇩🇿" },
    { label: "Angola", value: "+244", flag: "🇦🇴" },
    { label: "Argentina", value: "+54", flag: "🇦🇷" },
    { label: "Australia", value: "+61", flag: "🇦🇺" },
    { label: "Austria", value: "+43", flag: "🇦🇹" },
    { label: "Belgium", value: "+32", flag: "🇧🇪" },
    { label: "Benin", value: "+229", flag: "🇧🇯" },
    { label: "Brazil", value: "+55", flag: "🇧🇷" },
    { label: "Burkina Faso", value: "+226", flag: "🇧🇫" },
    { label: "China", value: "+86", flag: "🇨🇳" },
    { label: "Colombia", value: "+57", flag: "🇨🇴" },
    { label: "Cuba", value: "+53", flag: "🇨🇺" },
    { label: "Denmark", value: "+45", flag: "🇩🇰" },
    { label: "Djibouti", value: "+253", flag: "🇩🇯" },
    { label: "Egypt", value: "+20", flag: "🇪🇬" },
    { label: "France", value: "+33", flag: "🇫🇷" },
    { label: "Germany", value: "+49", flag: "🇩🇪" },
    { label: "Greece", value: "+30", flag: "🇬🇷" },
    { label: "India", value: "+91", flag: "🇮🇳" },
    { label: "Indonesia", value: "+62", flag: "🇮🇩" },
    { label: "Iran", value: "+98", flag: "🇮🇷" },
    { label: "Japan", value: "+81", flag: "🇯🇵" },
    { label: "Kenya", value: "+254", flag: "🇰🇪" },
    { label: "Liberia", value: "+231", flag: "🇱🇷" },
    { label: "Libya", value: "+218", flag: "🇱🇾" },
    { label: "Malaysia", value: "+60", flag: "🇲🇾" },
    { label: "Mexico", value: "+52", flag: "🇲🇽" },
    { label: "Morocco", value: "+212", flag: "🇲🇦" },
    { label: "Myanmar", value: "+95", flag: "🇲🇲" },
    { label: "Netherlands", value: "+31", flag: "🇳🇱" },
    { label: "Nigeria", value: "+234", flag: "🇳🇬" },
    { label: "Pakistan", value: "+92", flag: "🇵🇰" },
    { label: "Philippines", value: "+63", flag: "🇵🇭" },
    { label: "Poland", value: "+48", flag: "🇵🇱" },
    { label: "Russia", value: "+7", flag: "🇷🇺" },
    { label: "Singapore", value: "+65", flag: "🇸🇬" },
    { label: "South Africa", value: "+27", flag: "🇿🇦" },
    { label: "South Korea", value: "+82", flag: "🇰🇷" },
    { label: "Spain", value: "+34", flag: "🇪🇸" },
    { label: "Sri Lanka", value: "+94", flag: "🇱🇰" },
    { label: "Sweden", value: "+46", flag: "🇸🇪" },
    { label: "Switzerland", value: "+41", flag: "🇨🇭" },
    { label: "Turkey", value: "+90", flag: "🇹🇷" },
    { label: "United Kingdom", value: "+44", flag: "🇬🇧" },
    { label: "United States", value: "+1", flag: "🇺🇸" },
    { label: "Vietnam", value: "+84", flag: "🇻🇳" }
];

//validate mobile number
export const validatePhoneNumber = (mobile) => {
    // Extract country code from the mobile number
    const countryCode = countryPhoneCodes.find(({ value }) => mobile.startsWith(value))?.value;
    if (!countryCode) {
        return 'Invalid mobile number';
    }
    // Check if the country code exists in the list
    const country = countryPhoneCodes.find(c => c.value === countryCode.toString());
    if (!country) {
        return `Invalid mobile number`;
    }
    // Country-specific patterns
    const countrySpecificPatterns = {
        "+91": /^[6-9]\d{9}$/,       // India: 10 digits, starts with 6-9
        "+1": /^\d{10}$/,            // US/Canada: 10 digits
        "+44": /^\d{10}$/,           // UK: 10 digits
        "+61": /^\d{9}$/,            // Australia: 9 digits
        "+81": /^\d{10}$/,           // Japan: 10 digits
        "+49": /^\d{10,11}$/,        // Germany: 10-11 digits
        "+55": /^\d{10,11}$/,        // Brazil: 10-11 digits
        "+33": /^\d{9}$/,            // France: 9 digits
        "+62": /^\d{9,13}$/,         // Indonesia: 9-13 digits
        "+94": /^\d{9}$/,            // Sri Lanka: 9 digits
        "+27": /^\d{9}$/,            // South Africa: 9 digits
        "+82": /^\d{9,11}$/,         // South Korea: 9-11 digits
        "+234": /^\d{10}$/,          // Nigeria: 10 digits
        "+212": /^\d{9}$/,           // Morocco: 9 digits
        "+84": /^\d{9,11}$/,         // Vietnam: 9-11 digits
        "+52": /^\d{10}$/,           // Mexico: 10 digits
        "+7": /^\d{10}$/,            // Russia: 10 digits
        "+20": /^\d{9}$/,            // Egypt: 9 digits
    };
    // Use the country-specific pattern if defined, otherwise use a default pattern
    const pattern = countrySpecificPatterns[countryCode.toString()] || /^\d{7,14}$/;
    // Remove country code and validate the remaining number
    const numberWithoutCode = mobile.replace(countryCode, '');
    return pattern.test(numberWithoutCode) ? '' : 'Invalid mobile number';
};

export const FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];