import "./ListYourSpaceNew.css"
import { useNavigate } from "react-router-dom";
import { getProperty,updateProperty,generateDescription } from "../../services/api";
import FullPageLoader from "./Loader";
import { useState,useEffect } from "react";
//images start
import leftArrow from "../Images/BecomeHost/leftArrow.svg";
import step_7 from "../Images/BecomeHost/step_7.png";
//images end

const Description = () => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const segments = pathname?.split("/");
    let location_id = segments?.[4]
    const navigate = useNavigate()
    const [showLoader,setShowLoader] = useState(false)
    const [property,setProperty] = useState({})
    const [formData,setFormData] = useState({
        stay_description : {},
        event_description : {},
        film_description : {},
    })
    const [bookingTypes,setBookingTypes] = useState({
        film : null,
        event : null,
        stay : null,
    })
    const [errors, setErrors] = useState({});
    const onChange = (main,sub,value) => {
        setFormData({
            ...formData,
            [main] : {
                ...formData?.[main],
                [sub] : value,
            }
        })
    }
    const onBack = () => {
        if(bookingTypes?.film){
            navigate(`/apps/property/film/${location_id}`)
            return null
        }
        if(bookingTypes?.event){
            navigate(`/apps/property/event/${location_id}`)
            return null
        }
        if(bookingTypes?.stay){
            navigate(`/apps/property/stay/${location_id}`)
            return null
        }
    }
    const scrollToElementWithOffset = (element, offset) => {
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;
        
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
    }
    const validateForm = () => {
        const newErrors = {
            ...(bookingTypes?.stay && {
                stay_headline : formData?.stay_description?.headline ? "" : "Headline is required",
                stay_description : formData?.stay_description?.description ? "" : "Description is required",
            }),
            ...(bookingTypes?.event && {
                event_headline : formData?.event_description?.headline ? "" : "Headline is required",
                event_description : formData?.event_description?.description ? "" : "Description is required",
            }),
            ...(bookingTypes?.film && {
                film_headline : formData?.film_description?.headline ? "" : "Headline is required",
                film_description : formData?.film_description?.description ? "" : "Description is required",
            }),
        }
        // console.log("newErrors", newErrors);
        setErrors(newErrors);
        for(let key of Object.keys(newErrors)){
            if(newErrors[key] !== ''){
                const inputElement = document.getElementById(key);
                if (inputElement){
                    const headerHeight = 130;
                    scrollToElementWithOffset(inputElement, headerHeight);
                }
                return false
            }
        }
        return true
    }
    const onSubmit = async() => {
        if(!validateForm()){
            return null
        }
        setShowLoader(true)
        try{
            await updateProperty(formData,location_id)
            navigate(`/apps/property/gallery/${location_id}`)
        }catch(error){
            console.log(error)
        } finally {
            setShowLoader(false);
        }
    }
    const getDescription = async (data, OrgResp) => {
        setShowLoader(true)
        try{
            const resp = await generateDescription(data)
            console.log(resp?.data)
            setFormData({
                ...formData,
                stay_description : OrgResp?.data?.stay_description?.description ? OrgResp?.data?.stay_description :  resp?.data?.Staycations || {},
                event_description : OrgResp?.data?.event_description?.description ? OrgResp?.data?.event_description :  resp?.data?.Events || {},
                film_description : OrgResp?.data?.film_description?.description ? OrgResp?.data?.film_description :  resp?.data?.Shootings || {},
            })
        }catch(error){
            console.log(error)
        }
        setShowLoader(false)
    }
    const genNew = () => {
        let bookingType = []
        if(bookingTypes?.film){
            bookingType.push('Shootings')
        }
        if(bookingTypes?.event){
            bookingType.push('Events')
        }
        if(bookingTypes?.stay){
            bookingType.push('Staycations')
        }
        const prompt = {
            location_type : property?.location_type,
            booking_type : bookingType,
            city : property?.address?.city,
            amenities : [...Object.keys(property?.amenities)],
            features : [...Object.keys(property?.features)],
            entertainment : property?.entertainment,
            indoor_spaces : property?.indoor_spaces,
            outdoor_spaces : property?.outdoor_spaces,
        }
        getDescription(prompt)
    }
    const getData = async () => {
        setShowLoader(true)
        try{
            const resp = await getProperty(location_id);
            setProperty({...resp?.data});
            setBookingTypes({
                film : resp?.data?.film || null,
                event : resp?.data?.event || null,
                stay : resp?.data?.stay || null,
            })
            let bookingType = []
            if(resp?.data?.stay && !resp?.data?.stay_description?.description){
                bookingType.push('Staycations')
            }
            if(resp?.data?.event && !resp?.data?.event_description?.description){
                bookingType.push('Events')
            }
            if(resp?.data?.film && !resp?.data?.film_description?.description){
                bookingType.push('Shootings')
            }
            if(bookingType?.length > 0){
                const prompt = {
                    location_type : resp?.data?.location_type,
                    booking_type : bookingType,
                    city : resp?.data?.address?.city,
                    amenities : [...Object.keys(resp?.data?.amenities)],
                    features : [...Object.keys(resp?.data?.features)],
                    entertainment : resp?.data?.entertainment,
                    indoor_spaces : resp?.data?.indoor_spaces,
                    outdoor_spaces : resp?.data?.outdoor_spaces,
                }
                getDescription(prompt, resp)
            }else{
                setFormData({
                    stay_description : resp?.data?.stay_description || {},
                    event_description : resp?.data?.event_description || {},
                    film_description : resp?.data?.film_description || {},
                })
                setShowLoader(false)
            }
        }catch(error){
            console.log(error)
        } finally {
            setShowLoader(false);
        }
    }
    useEffect(()=>{
        if(location_id){
            getData()
        }
    },[location_id])
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    }
    useEffect(()=> {
        scrollToTop()
    },[])
    // console.log(formData)
    return (
        <>
        <div className="become_a_host_wrap">
            <div className="become_a_host">
                <div className="become_a_host_sub_wrap">
                    <div className="become_a_host_sub">
                        <span className="sub_text heading_color_1">Property Description</span>

                        <hr className="w-100 mt-4 mb-4"/>
                        {bookingTypes?.stay && (
                            <>
                                <span className="sub_text heading_color_1">Staycation / Homestay</span>
                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="stay_headline">Headline <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={8}
                                        id='Staycation'
                                        name='headline'
                                        value={formData?.stay_description?.headline}
                                        onChange={(e)=> onChange('stay_description',e.target.name,e.target.value)}
                                        placeholder='Enter your headline'
                                        className='become_a_host_text_area_input_head mt-1'
                                    />
                                </div>
                                {errors?.stay_headline && <span className="content text-danger">{errors?.stay_headline}</span>}
                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="stay_description">Description <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={55}
                                        id='Staycation'
                                        name='description'
                                        value={formData?.stay_description?.description}
                                        onChange={(e)=> onChange('stay_description',e.target.name,e.target.value)}
                                        placeholder='Enter your description'
                                        className='become_a_host_text_area_input mt-1'
                                    />
                                </div>
                                {errors?.stay_description && <span className="content text-danger">{errors?.stay_description}</span>}
                            </>
                        )}
                        {bookingTypes?.event && (
                            <>
                                <hr className="w-100 mt-4 mb-4"/>
                                <span className="sub_text heading_color_1">Event Venue</span>
                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="event_headline">Headline <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={8}
                                        id='Events'
                                        name='headline'
                                        value={formData?.event_description?.headline}
                                        onChange={(e)=> onChange('event_description',e.target.name,e.target.value)}
                                        placeholder='Enter your headline'
                                        className='become_a_host_text_area_input_head mt-1'
                                    />
                                </div>
                                {errors?.event_headline && <span className="content text-danger">{errors?.event_headline}</span>}
                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="event_description">Description <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={55}
                                        id='Events'
                                        name='description'
                                        value={formData?.event_description?.description}
                                        onChange={(e)=> onChange('event_description',e.target.name,e.target.value)}
                                        placeholder='Enter your description'
                                        className='become_a_host_text_area_input mt-1'
                                    />
                                </div>
                                {errors?.event_description && <span className="content text-danger">{errors?.event_description}</span>}
                            </>    
                        )}
                        {bookingTypes?.film && (
                            <>
                                <hr className="w-100 mt-4 mb-4"/>
                                <span className="sub_text heading_color_1">Shooting Location</span>
                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="film_headline">Headline <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={8}
                                        id='Film'
                                        name='headline'
                                        value={formData?.film_description?.headline}
                                        onChange={(e)=> onChange('film_description',e.target.name,e.target.value)}
                                        placeholder='Enter your headline'
                                        className='become_a_host_text_area_input_head mt-1'
                                    />
                                </div>
                                {errors?.film_headline && <span className="content text-danger">{errors?.film_headline}</span>}

                                <div className="d-flex flex-column w-100 mt">
                                    <span className="content heading_color_1" id="film_description">Description <span className="required"></span> </span>
                                    <textarea
                                        rows={4}
                                        columns={55}
                                        id='Film'
                                        name='description'
                                        value={formData?.film_description?.description}
                                        onChange={(e)=> onChange('film_description',e.target.name,e.target.value)}
                                        placeholder='Enter your description'
                                        className='become_a_host_text_area_input mt-1'
                                    />
                                </div>
                                {errors?.film_description && <span className="content text-danger">{errors?.film_description}</span>}
                            </>
                        )}
                        <div className="mt-4">
                            <button type="button" onClick={genNew} className="btn btn-primary sp_button">Generate new description</button>
                        </div>
                    </div>
                    <div className="become_a_host_sub_right_wrap">
                        <img src={step_7} alt="step_7" className="mb-2"/>
                        <span className="content text_color_1 text-center mb-2">SpotLet will help craft the perfect headline and description for your property, tailored for staycations, events, and film shoots to maximize appeal.</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="become_a_host_footer">
            <div className="d-flex flex-row justify-content-center align-items-center cursor" onClick={onBack}>
                <img src={leftArrow} alt="leftArrow"/>
                <span className="ml-2 sub_text text_color_1">Back</span>
            </div>
            <button type="button" onClick={onSubmit} className="btn btn-primary sp_button">Save & Continue</button>
        </div>
        {showLoader && <FullPageLoader/>}
        </>
    )
}
export default Description;