import { useState } from "react";
import ModalWrapper from "./ModalWrapper/ModalWrapper";

const NewTypeModal = (props) => {
    const {title} = props;
    const [newType, setNewType] = useState("");
    const [error,setError] = useState('')
    const validateType = (inputValue) => {
        const regex = /^[a-zA-Z!@#$%^&*()_+={}[\]:;"'<>,.?/\\| -]*$/;
        if (!inputValue || inputValue.trim() === "") {
            setError("Type should not be empty or null.")
            return null
        }
        if (inputValue.length > 50) {
            setError("Type should not exceed 50 characters.")
            return null
        }
        if (!regex.test(inputValue)) {
            setError("Only alphabets and special characters are allowed.")
            return null
        }
        return true
    }
    const onSubmit = (e) => {
        e?.preventDefault();
        if(validateType(newType)){
            props?.onSuccess(newType);
        }
    }

    return(
        <ModalWrapper heading={title} props={props} size="md">
            <form className="d-flex flex-row" onSubmit={onSubmit}>
                <input placeholder="Add New Type" type = "text" className="sp_input" onChange = { (e) => {
                    setNewType(e?.target?.value)
                    setError('')
                }} />
                <button className="btn btn-primary sp_button sp_ml_1 align-self-end" type="submit"> Submit </button>
            </form>
            {error && <span className="text-danger">{error}</span>}
        </ModalWrapper>
    )
}

export default NewTypeModal