/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import { useAuth } from '../../../../../app/modules/auth';

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser,sidebarCountObject} = useAuth()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {currentUser?.permissions?.Notifications?.read && (
      <SidebarMenuItem
        to='/apps/notifications/list'
        icon='abstract-13'
        title={`Notifications${' '}(${sidebarCountObject?.notifications || 0})`}
        fontIcon='bi-layers'
      />
      )} 
      {currentUser?.permissions?.Conversations?.read && (
      <SidebarMenuItem
        to='/apps/conversations/list'
        icon='abstract-13'
        title={`Conversations`}
        // title={`Conversations${' '}(${sidebarCountObject?.conversations || 0})`}
        fontIcon='bi-layers'
      />
      )} 
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>All Managements</span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to=''
        icon='shield-search'
        title='Lookup Management'
        fontIcon='bi-layers'
      >
          {currentUser?.permissions?.Amenities_Management?.read && (<SidebarMenuItem to='/apps/location-management/typeOFProperty' title='Type of Property Lookups' hasBullet={true} /> )}
        {currentUser?.permissions?.Top_Rated_Locations?.read && (<SidebarMenuItem to='/apps/location-management/amenities' title='Amenities Lookups' hasBullet={true} /> )}
        {currentUser?.permissions?.City_Management?.read && (<SidebarMenuItem to='/apps/location-management/cities' title='Cities Lookups' hasBullet={true} /> )}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='Listing Management'
        fontIcon='bi-chat-left'
        icon='map'
      >
        {currentUser?.permissions?.Incomplete_Locations?.read && (<SidebarMenuItem to='/apps/location-management/incomplete' title={`Incomplete Listings${' '}(${sidebarCountObject?.incompleteLisitng || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Location_Requests?.read && (<SidebarMenuItem to='/apps/location-management/requests' title={`Listing Requests${' '}(${sidebarCountObject?.listingRequests || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Location_for_Approval?.read && (<SidebarMenuItem to='/apps/location-management/reviewed' title={`Listing for approval${' '}(${sidebarCountObject?.verified || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Approved_Locations?.read && (<SidebarMenuItem to='/apps/location-management/approved' title={`Approved Listings${' '}(${sidebarCountObject?.approved || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Rejected_Locations?.read && (<SidebarMenuItem to='/apps/location-management/rejected' title={`Rejected Listings${' '}(${sidebarCountObject?.rejected || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Deactivated_Locations?.read && (<SidebarMenuItem to='/apps/location-management/deactivated' title={`Deactivated Listings${' '}(${sidebarCountObject?.deactivated || 0})`} hasBullet={true} /> )}
        {currentUser?.permissions?.Edited_locations?.read && (<SidebarMenuItem to='/apps/location-management/edited' title={`Edited Listings${' '}(${sidebarCountObject?.edited || 0})`} hasBullet={true} /> )}
        {currentUser?.permissions?.Featured_Locations?.read && (<SidebarMenuItem to='/apps/location-management/featured' title={`Featured Listings${' '}(${sidebarCountObject?.featured || 0})`} hasBullet={true} /> )}
        {currentUser?.permissions?.Top_Rated_Locations?.read && (<SidebarMenuItem to='/apps/location-management/exclusive' title={`Exclusive Listings${' '}(${sidebarCountObject?.exclusive || 0})`} hasBullet={true} /> )}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='Staff Management'
        fontIcon='bi-layers'
        icon='address-book'
      >
        {currentUser?.permissions?.Staff_Management?.read && ( <SidebarMenuItem to='/apps/staff-management/staff' title='Staff List' hasBullet={true}/>)}
        {currentUser?.permissions?.Role_Management?.read && (<SidebarMenuItem to='/apps/role-management/list' title='Roles List' hasBullet={true} />)}
        {currentUser?.permissions?.Permission_Management?.read && (<SidebarMenuItem to='/apps/role-management/permissions' title='Permissions List' hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='User Management'
        fontIcon='bi-layers'
        icon='abstract-20'
      >
        {currentUser?.permissions?.User_Management?.read && ( <SidebarMenuItem to='/apps/users/list' title={`Users List${' '}(${sidebarCountObject?.users || 0})`} hasBullet={true}/>)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='abstract-9'
        title='Support Management'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Priority_Management?.read && (<SidebarMenuItem to='/apps/prioritysupport/list' title={`Priority Support${' '}(${sidebarCountObject?.prioritySupport || 0})`} hasBullet={true} />)}
        {currentUser?.permissions?.Priority_Management?.read && (<SidebarMenuItem to='/apps/userDeactivateRequest/list' title={`User Deactivate Request ${' '}(${sidebarCountObject?.userDeactivateRequests || 0})`} hasBullet={true} />)}
        {/* {currentUser?.permissions?.Enquiry_Management?.read && (<SidebarMenuItem to='/apps/enquiries' title='Enquiry Support' fontIcon='bi-layers' hasBullet={true} />)} */}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='abstract-33'
        title='CMS Management'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Payment_Management?.read && (<SidebarMenuItem to='/apps/web-app-management'  title='Webapp Management'  hasBullet={true} />)}
        {currentUser?.permissions?.Cms_management?.read && (<SidebarMenuItem to='/apps/cms' title='Meta Management'  hasBullet={true} />)}
        {currentUser?.permissions?.Testimonial_Management?.read && (<SidebarMenuItem to='/apps/testimonials' title='Testimonial Management'  hasBullet={true} />)}
        {currentUser?.permissions?.Email_Management?.read && (<SidebarMenuItem  to='/apps/email'  title='Email Templates'  hasBullet={true} />)}
        {currentUser?.permissions?.Notification_Management?.read && (<SidebarMenuItem to='/apps/notification'  title='Notification Templates'   hasBullet={true} />)}
        {currentUser?.permissions?.Tag_management?.read && (<SidebarMenuItem to='/apps/activities-management'  title='Activities Management'   hasBullet={true} />)}
        {currentUser?.permissions?.Blog_management?.read && (<SidebarMenuItem to='/apps/blogs-management'  title='Blogs Management'   hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='binance-usd'
        title='Career Management'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Career_Management?.read && (<SidebarMenuItem to='/apps/career' title='Careers List' hasBullet={true}/>)}
        {currentUser?.permissions?.Job_Management?.read && (<SidebarMenuItem  to='/apps/jobrequests' title='Applied Directly' hasBullet={true}/>)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='abstract-14'
        title='Lead Management'
        fontIcon='bi-layers'
      >
        {/* {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/list' title='Listings Leads'  hasBullet={true} />)} */}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/find-my-spot' title={`Find My Spot${' '}(${sidebarCountObject?.findMySpot || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/event-planning' title={`Event Planning${' '}(${sidebarCountObject?.eventPlanning || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/subscription-enquiry' title={`Subscription Enquiry${' '}(${sidebarCountObject?.subscription || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/contact-host' title={`Contact Host${' '}(${sidebarCountObject?.contactHost || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/booking-requests' title={`Booking Requests${' '}(${sidebarCountObject?.bookingRequest || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/get-in-touch' title={`Property Get In Touch${' '}(${sidebarCountObject?.getInTouch || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/claim-property' title={`Claim Property Requests${' '}(${sidebarCountObject?.claimProperty || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/incorrect-info-reports' title={`Property Incorrect Info Reports${' '}(${sidebarCountObject?.reportIncorrectInfo || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/schedule-visit' title={`Schedule Property Visit${' '}(${sidebarCountObject?.schedulePropertyVisit || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/host-get-in-touch' title={`Host Get In Touch${' '}(${sidebarCountObject?.hostGetInTouch || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/venue-enquiries' title={`Venue Enquiries${' '}(${sidebarCountObject?.venueEnquiries || 0})`}  hasBullet={true} />)}
        {currentUser?.permissions?.Lead_Management?.read && ( <SidebarMenuItem to='/apps/lead/film-shoot-assistance' title={`Film Shoot Assistance${' '}(${sidebarCountObject?.filmShootAssistance || 0})`}  hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='abstract-27'
        title='Logs Management'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Audit_Logs?.read && (<SidebarMenuItem to='/apps/logs' title='All Logs' hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='element-plus'
        title='Coupon Management'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Coupon_Management?.read && (<SidebarMenuItem  to='/apps/coupon' title='Coupons List' hasBullet={true}/>)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        icon='dollar'
        title='Subscriptions'
        fontIcon='bi-layers'
      >
        {currentUser?.permissions?.Subscription_Management?.read && (<SidebarMenuItem  to='/apps/subscriptions/list' title='Subscriptions List' hasBullet={true}/>)}
      </SidebarMenuItemWithSub>
      {/* <SidebarMenuItemWithSub
        to=''
        title='Review Management'
        fontIcon='bi-chat-left'
        icon='abstract-46'
      >
        {currentUser?.permissions?.Locations_Reviews?.read && (<SidebarMenuItem to='/apps/review' title='Listings Reivews' hasBullet={true} />)}
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub
        to=''
        title='Booking Management'
        fontIcon='calendar'
        icon='abstract-15'
      >
        {currentUser?.permissions?.Booking_Management?.read && (<SidebarMenuItem to='/apps/bookings/list' title='Booking Requests' hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to=''
        title='Payment Management'
        fontIcon='calendar'
        icon='abstract-36'
      >
        <SidebarMenuItem to='/apps/payments/create' title='Create Payment' hasBullet={true} />
        {currentUser?.permissions?.Booking_Management?.read && (<SidebarMenuItem to='/apps/razorpay/list' title='Razerpay Payments' hasBullet={true} />)}
      </SidebarMenuItemWithSub>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
